import { Injectable } from '@angular/core';
import {
  Claim,
  ClaimBundleType,
  ClaimJourneyStateType,
} from '@domgen/dgx-components';
import { ProductTypeFormConfig } from '@domgen/dgx-components';
import { Api } from '@domgen/dgx-components';
import { ClaimEffectHelperService } from './claim-effect-helper.service';
import { CalendarSlot, CalendarDay } from '@domgen/dgx-components';
import { FormCollectionData } from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class ClaimHelperService {
  static readonly TELEVISION_REGEX = /^\s*Television\s-\s/;
  static readonly COOKER_REGEX = /COOKER|OVEN|HOB/i;

  constructor(private effectsHelper: ClaimEffectHelperService) {}

  getProductTypeForUpdateClaim(claim: Claim) {
    const productType =
      claim.claimSelection?.request?.ProductType || claim.reflect?.productType;

    return productType;
  }

  isTelevision(types: string[]) {
    return types.some((val) => val.match(ClaimHelperService.TELEVISION_REGEX));
  }

  getProductTypesRadiosConfig(types: string[] = []): ProductTypeFormConfig {
    if (this.isTelevision(types)) {
      return {
        header: 'Select the size of your TV screen',
        controlName: 'productType',
        showIcon: true,
        message: 'Select your screen size',
      };
    }

    if (types.some((val) => val.match(ClaimHelperService.COOKER_REGEX))) {
      return {
        header: 'What type of cooker do you have?',
        controlName: 'productType',
        showIcon: false,
        message: 'Select your cooker type',
      };
    }

    return {
      // defaults in case TV or Cooker falls through
      header: 'Select your product type',
      controlName: 'productType',
      showIcon: false,
      message: 'Select your product type',
    };
  }

  /**
   * In GetMandatoryData, when BookingOEM is null and OEMApplianceData array is empty,
   * We need to manually add an alphanumeric free text Model Number field as default.
   * @param getData GetMandatoryDataResponse
   * @param excludeOptionalInputs to support legacy appliance details that cater for optional inputs
   */
  defaultModelInput(
    getData: Api.GetMandatoryDataResponse,
    excludeOptionalInputs = true
  ): {
    readonly oemApplianceData: Api.OEMApplianceData[];
    isFreeTextModelNumber: boolean;
  } {
    const isFreeTextModelNumber =
      this.effectsHelper.isFreeTextModelNumber(getData);
    // replace readonly lock
    let oemApplianceData = [...getData.OEMApplianceData];

    if (isFreeTextModelNumber) {
      const modelNumberInsert: Api.OEMApplianceData = {
        FieldIdentifier: 'UniqueApplianceID',
        FieldLabel: 'Model Number',
        FieldType: 'String',
        FieldRegex: '.{0,20}',
        FieldLookupRegex: null,
        FieldValues: [],
        DataCategory: null,
        DataType: 'ModelNumber',
        UseGetData: false,
        DisplayPriority: 0,
      };
      const modelNumberIdx = oemApplianceData.findIndex(
        (field) => field.DataType === 'ModelNumber'
      );
      if (modelNumberIdx > -1) {
        oemApplianceData[modelNumberIdx] = modelNumberInsert;
      } else {
        oemApplianceData.push(modelNumberInsert);
      }
    }

    // TODO: remove excludeOptionalInputs check once legacy appliance details is replaced
    if (excludeOptionalInputs) {
      // filter out serial number from oemApplianceData
      oemApplianceData = oemApplianceData.filter(
        (applianceData) =>
          applianceData.FieldIdentifier !== 'SerialNumber' &&
          applianceData.DataType !== 'SerialNumber'
      ) as Api.OEMApplianceData[];

      // filter Model number for PNC lookup
      if (oemApplianceData.find((a) => a.DataType === 'PNCNumber')) {
        oemApplianceData = oemApplianceData.filter(
          (applianceData) => applianceData.DataType !== 'ModelNumber'
        ) as Api.OEMApplianceData[];
      }
    }

    return { oemApplianceData, isFreeTextModelNumber };
  }

  getApplianceDetailType(claim: Claim): string {
    return this.effectsHelper.pncRequired(
      claim.getData as Api.GetMandatoryDataResponse
    )
      ? 'Product Number Code (PNC) and ML code'
      : 'model number';
  }

  getModelNumberHeading(claim: Claim): string | null {
    if (claim?.getData) {
      const isFreeTextModelNumber = this.effectsHelper.isFreeTextModelNumber(
        claim?.getData
      );

      if (isFreeTextModelNumber) {
        return (claim.getData?.ModelNumber.length as number) > 0
          ? (claim.getData?.ModelNumber as string)
          : null;
      }
    }

    if (claim?.getData?.BookingOEM === 'Whirlpool MB') {
      // Whirlpool MB should never have model number in header
      return null;
    }

    if (this.isClaimCreated(claim)) {
      return claim.getData?.ModelNumber as string;
    }

    if (
      this.effectsHelper.pncRequired(
        claim.getData as Api.GetMandatoryDataResponse
      ) &&
      this.effectsHelper.pncNotEntered(claim)
    ) {
      // Electrolux should not show model number until PNC selected
      return null;
    }

    return claim?.getData?.UniqueApplianceID === null
      ? null
      : (claim?.getData?.ModelNumber as string);
  }

  isClaimCreated(claim: Claim): boolean {
    return claim?.reflect?.journeyState !== ClaimJourneyStateType.START;
  }

  isASV(claim: Claim): boolean {
    return (
      claim?.reflect?.asvOffered === 'true' &&
      claim?.reflect?.claimType === ClaimBundleType.ANNUAL_SERVICE
    );
  }

  getBookingSlotIdentifier(
    currentDate: CalendarDay,
    slot: CalendarSlot,
    isRebook: boolean = false
  ): string | null {
    let identifier = slot.identifier;

    if (isRebook) {
      if (slot.slotType === 'AllDay') {
        identifier = 'AllDay';
      }

      if (slot.slotType === 'Specific') {
        const s = this.getSlot(currentDate?.slots as CalendarSlot[], slot);
        identifier = `${s.startTime}-${s.endTime}`;
      }
    }

    return identifier;
  }

  getSlot(slots: CalendarSlot[], time: CalendarSlot): CalendarSlot {
    return slots?.find(
      (s: CalendarSlot) => s.identifier === time.identifier
    ) as CalendarSlot;
  }

  getCollectionData(
    collectionData: FormCollectionData | null
  ): Api.CollectionData {
    let collectionDataRequest = {};

    if (collectionData?.COLLECTION_ADDRESS) {
      collectionDataRequest = {
        COLLECTION_ADDRESS: {
          BuildingName: '',
          HouseStreetName:
            collectionData.COLLECTION_ADDRESS.formValues.addressLine1,
          LocalArea: collectionData.COLLECTION_ADDRESS.formValues.addressLine2,
          TownCity: collectionData.COLLECTION_ADDRESS.formValues.city,
          PostCode: collectionData.COLLECTION_ADDRESS.formValues.postcode,
        },
      };
    }

    if (collectionData?.DELIVERY_RETURN_ADDRESS) {
      collectionDataRequest = {
        ...collectionDataRequest,
        DELIVERY_RETURN_ADDRESS: {
          BuildingName: '',
          HouseStreetName:
            collectionData.DELIVERY_RETURN_ADDRESS.formValues.addressLine1,
          LocalArea:
            collectionData.DELIVERY_RETURN_ADDRESS.formValues.addressLine2,
          TownCity: collectionData.DELIVERY_RETURN_ADDRESS.formValues.city,
          PostCode: collectionData.DELIVERY_RETURN_ADDRESS.formValues.postcode,
        },
      };
    }

    return collectionDataRequest;
  }
}
