export * from './lib/claims-feature-shell.module';
export * from './lib/claims-feature-shell-routing.module';
export { ClaimsAppComponentService } from './lib/claims-app-component.service';

export * from './lib/_shared/guards/appliance-details.guard';
export * from './lib/_shared/guards/auth.guard';
export * from './lib/_shared/guards/repair-details.guard';

export * from './lib/book-callback/book-callback.module';
export * from './lib/book-collection/book-collection.module';
export * from './lib/book-dropoff/book-dropoff.module';
export * from './lib/book-selfsend/book-selfsend.module';
export * from './lib/error-page/error-page.module';
