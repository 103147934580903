import { ChangeDetectionStrategy, Component } from '@angular/core';
import { fadeInAnimation } from '@domgen/dgx-components';
import { BookCallbackStateService } from './book-callback-data-access/book-callback-state.service';

@Component({
  selector: 'claims-book-callback',
  templateUrl: './book-callback.component.html',
  styleUrls: ['./book-callback.component.scss'],
  providers: [BookCallbackStateService],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookCallbackComponent {
  vm$ = this._state.vm$;

  constructor(private _state: BookCallbackStateService) {}

  onSubmit() {
    this._state.setSubmitLoading(true);
  }
}
