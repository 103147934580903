import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderLinks } from '@domgen/dgx-components';
import { NavigationLink } from '@domgen/dgx-fe-business-models';
import { ClaimsAppComponentService } from '@domgen/feature-shell';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'ao-app-root',
  templateUrl: './ao.component.html',
  styleUrls: ['./ao.component.scss'],
})
export class AoComponent {
  links$: Observable<HeaderLinks> = this.service.links$;
  loadingState$: Observable<{
    isLoading: boolean;
    message: string | null;
  }> = this.service.loadingState$;
  private homepageURL: string;

  constructor(public readonly service: ClaimsAppComponentService) {}

  public headerNavigationLinks$ = this.links$.pipe(
    tap((links) => {
      this.homepageURL = links.homeURL;
    }),
    map((links) => ({
      ...this.headerNavigationLinks,
      myAccountHome: {
        href: links.plansURL,
        icon: '',
        label: 'My plans',
        isHidden: false,
      },
      myDetails: {
        href: links.personalDetailsURL,
        label: 'My details',
        icon: '',
        isHidden: false,
      },
    }))
  );

  private headerNavigationLinks = {
    home: {
      href: 'https://www.ao-care.com',
      label: 'home',
      isHidden: false,
    },
    products: {
      label: 'Get protected',
      href: 'https://www.sales.ao-care.com/',
      isHidden: false,
    },
    repair: {
      href: 'https://www.ao-care.com/RepairClaimPageView',
      label: 'Book a repair',
      isHidden: false,
    },
    faq: {
      label: 'Help and advice',
      href: 'https://www.ao-care.com/content/ao-care-help-advice/ao-faqs',
      isHidden: false,
    },
    profile: {
      label: 'My account',
      icon: 'my-account',
      routerPath: '',
      isHidden: false,
    },
    logout: {
      label: 'Logout',
      icon: '',
      routerPath: '',
      isHidden: false,
    },
    basket: {
      href: '/#',
      icon: 'basket',
      label: 'Basket',
      isHidden: true,
    },
  };
  footerProtectLinks: NavigationLink[] = [
    {
      section: 'protect',
      label: 'Household appliances',
      href: 'https://www.ao-care.com/products-5/appliances-household',
      newWindow: true,
    },
  ];

  footerUsefulLinks: NavigationLink[] = [
    {
      section: 'useful',
      label: 'Help and advice',
      href: 'https://www.ao-care.com/content/ao-care-help-advice/ao-faqs',
      newWindow: true,
    },
    {
      section: 'useful',
      label: 'Contact us',
      href: 'https://www.ao-care.com/content/contact-us',
      newWindow: true,
    },
    {
      section: 'useful',
      label: 'ao.com',
      href: 'https://ao.com/',
      newWindow: true,
    },
    {
      section: 'useful',
      label: 'Regulatory information',
      href: 'https://www.ao-care.com/company-information-legal',
      newWindow: true,
    },
    {
      section: 'useful',
      label: 'Modern Slavery Statement',
      href: 'https://www.ao-care.com/modern-slavery',
      newWindow: true,
    },
  ];
  footerLegalLinks: NavigationLink[] = [
    {
      section: 'legal',
      label: 'Website Terms and Conditions',
      href: 'https://www.ao-care.com/shop/en/dg/web-terms-conditions',
      newWindow: true,
    },
    {
      section: 'legal',
      label: 'Privacy and Cookies Policy',
      href: 'https://www.ao-care.com/shop/en/dg/privacy-cookies-policy',
      newWindow: true,
    },
    {
      section: 'legal',
      label: 'Accessibility Policy',
      href: 'https://www.ao-care.com/shop/en/dg/accessibility',
      newWindow: true,
    },
  ];

  public logout() {
    this.service.logout(this.homepageURL);
  }
}
