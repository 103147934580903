import { ClaimHelperService } from '@domgen/data-access-claims';
import { ProductDetails } from '@domgen/dgx-components';
import {
  ClaimsAnalyticsService,
  ErrorContent,
  ErrorType,
} from '@domgen/dgx-components';
import {
  Availability,
  Claim,
  ServiceCollectionData,
} from '@domgen/dgx-components';
import { isArray } from 'lodash-es';
import {
  CalendarDay,
  CalendarSlot,
  CalendarHelperService,
} from '@domgen/dgx-components';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  ClaimEffectHelperService,
  ClaimFacade,
} from '@domgen/data-access-claims';
import { Observable, OperatorFunction } from 'rxjs';
import { tap, withLatestFrom, filter, map, take } from 'rxjs/operators';
import { format, isSameDay } from 'date-fns';
import { Api } from '@domgen/dgx-components';
import { ServiceOption } from '@domgen/dgx-components';
import {
  ContactDetailsFormValues,
  ContactDetailsFormValuesContainer,
} from '@domgen/dgx-components';
import { BookingAnalyticsService } from '@domgen/dgx-components';

export interface LocalState {
  currentDateSlotTime: CalendarSlot | null;
  selectedDate: CalendarDay | null;
  currentDate: CalendarDay | null;
  slots: CalendarSlot[] | null;
  showErrorPrompt: boolean;
  timePickerError: string;
  pageLoading: boolean;
  silentSlotsLoading: boolean;
  extraSlotsLoading: boolean;
  availability: Availability;
  selectTimeGALoading: boolean;
  selectDateGALoading: boolean;
  bookingErrorGALoading: boolean;
  addressSavedLoading: boolean;
  bookingError: string;
  submitLoading: boolean;
  collectionData: FormCollectionData | null;
  errorContent?: ErrorContent;
  errorCount?: number;
}

export interface FormCollectionData {
  [ServiceCollectionData.COLLECTION_ADDRESS]?: ContactDetailsFormValuesContainer;
  [ServiceCollectionData.DELIVERY_RETURN_ADDRESS]?: ContactDetailsFormValuesContainer;
}

@Injectable()
export class BookingStateService extends ComponentStore<LocalState> {
  readonly dateError = 'Select an available date';
  readonly timeDateError = 'Select an available date and time';

  readonly selectedDate$ = this.select((state) => state.selectedDate);
  readonly currentDate$ = this.select((state) => state.currentDate);
  readonly silentSlotsLoading$ = this.select(
    (state) => state.silentSlotsLoading
  );
  readonly pageLoading$ = this.select((state) => state.pageLoading);
  readonly availability$ = this.select((state) => state.availability);
  readonly extraSlotsLoading$ = this.select((state) => state.extraSlotsLoading);
  readonly selectTimeGALoading$ = this.select(
    (state) => state.selectTimeGALoading
  );
  readonly selectDateGALoading$ = this.select(
    (state) => state.selectDateGALoading
  );

  readonly addressSavedLoading$ = this.select(
    (state) => state.addressSavedLoading
  );

  readonly showErrorPrompt$ = this.select((state) => state.showErrorPrompt);

  readonly submitLoading$ = this.select((state) => state.submitLoading);

  readonly bookingErrorGALoading$ = this.select(
    (state) => state.bookingErrorGALoading
  );
  readonly bookingError$ = this.select((state) => state.bookingError);
  readonly errorCount$ = this.select((state) => state.errorCount);

  readonly activeClaim$ = this._claim.activeClaim$ as Observable<Claim>;

  readonly bookingOption$ = this._claim
    .bookingDates$ as Observable<Api.PutServiceOptionResponse>;

  readonly bookingApiError$: Observable<any> = this._claim.bookingError$;

  readonly serviceOption$ = this._claim
    .serviceOption$ as Observable<ServiceOption>;

  readonly collectionData$ = this.select((state) => state.collectionData);

  readonly getCollectionAddress$ = this.serviceOption$.pipe(
    map(
      (opt) =>
        opt?.ServiceOptionRequiredFields &&
        opt.ServiceOptionRequiredFields.includes(
          ServiceCollectionData.COLLECTION_ADDRESS
        )
    ),
    withLatestFrom(this.activeClaim$),
    map(([show, claim]: [boolean, Claim]) => {
      if (show) {
        this.setCollectionData({
          COLLECTION_ADDRESS: {
            formValues: {
              addressLine1: claim.customer?.CustomersHouseStreetName as string,
              addressLine2: '',
              city: claim.customer?.CustomersTownCity as string,
              county: claim.customer?.CustomersLocalArea as string,
              postcode: claim.customer?.CustomersPostCode as string,
            },
          },
        });
      }

      return show;
    })
  );

  readonly getDeliveryAddress$ = this.serviceOption$.pipe(
    map(
      (opt) =>
        opt?.ServiceOptionRequiredFields &&
        opt.ServiceOptionRequiredFields.includes(
          ServiceCollectionData.DELIVERY_RETURN_ADDRESS
        )
    ),
    withLatestFrom(this.activeClaim$),
    map(([show, claim]: [boolean, Claim]) => {
      if (show) {
        this.setCollectionData({
          DELIVERY_RETURN_ADDRESS: {
            formValues: {
              addressLine1: claim.customer?.CustomersHouseStreetName as string,
              addressLine2: '',
              city: claim.customer?.CustomersTownCity as string,
              county: claim.customer?.CustomersLocalArea as string,
              postcode: claim.customer?.CustomersPostCode as string,
            },
          },
        });
      }

      return show;
    })
  );

  readonly currentDateSlotTime$ = this.select(
    (state) => state.currentDateSlotTime
  ) as Observable<CalendarSlot>;

  readonly isASV$ = this.activeClaim$.pipe(
    map((claim: Claim) => this._analytics.isASV(claim))
  );

  readonly product$: Observable<ProductDetails> = this.activeClaim$.pipe(
    map((claim: Claim) => {
      return {
        brand: claim.reflect?.manufacturer as string,
        applianceName: claim.reflect?.productType as string,
        planNumber: claim.reflect?.planNumber as string,
        modelNumber: this._claimHelper.getModelNumberHeading(claim),
      } as ProductDetails;
    })
  );

  readonly vm$ = this.select(
    this.select((state) => state),
    this.activeClaim$,
    this.bookingOption$,
    (state, claim, bookingOption) => ({
      ...state,
      claim,
      bookingOption,
    })
  );

  constructor(
    private _claim: ClaimFacade,
    private _calendarHelper: CalendarHelperService,
    private _bookingAnalytics: BookingAnalyticsService,
    private _analytics: ClaimsAnalyticsService,
    private _claimHelper: ClaimHelperService,
    private _claimEffectsHelper: ClaimEffectHelperService
  ) {
    super({
      currentDateSlotTime: null,
      selectedDate: null,
      currentDate: null,
      slots: [],
      showErrorPrompt: false,
      timePickerError: '',
      pageLoading: false,
      silentSlotsLoading: false,
      extraSlotsLoading: false,
      availability: {
        bookingDates: [],
        totalAvailability: 0,
        blockAvailability: 0,
      } as Availability,
      selectTimeGALoading: false,
      selectDateGALoading: false,
      bookingErrorGALoading: false,
      errorContent: {
        body: '',
        cta: '',
      },
      addressSavedLoading: false,
      bookingError: '',
      submitLoading: false,
      collectionData: null,
      errorCount: 0,
    });
  }

  // Updaters

  readonly setCurrentDateSlotTime = this.updater(
    (state, currentDateSlotTime: CalendarSlot | null) => ({
      ...state,
      currentDateSlotTime,
    })
  );

  readonly setCurrentDate = this.updater(
    (state, currentDate: CalendarDay | null) => ({
      ...state,
      currentDate,
    })
  );

  readonly setSelectedDate = this.updater(
    (state, selectedDate: CalendarDay) => ({
      ...state,
      selectedDate,
    })
  );

  readonly setSlots = this.updater((state, slots: CalendarSlot[] | null) => ({
    ...state,
    slots,
  }));

  readonly setPageLoading = this.updater((state, PageLoading: boolean) => ({
    ...state,
    PageLoading,
  }));

  readonly setSilentSlotsLoading = this.updater(
    (state, silentSlotsLoading: boolean) => ({
      ...state,
      silentSlotsLoading,
    })
  );

  readonly setAvailability = this.updater(
    (state, availability: Availability) => ({
      ...state,
      availability,
    })
  );

  readonly setShowErrorPrompt = this.updater(
    (state, showErrorPrompt: boolean) => ({
      ...state,
      showErrorPrompt,
    })
  );

  readonly setTimePickerError = this.updater(
    (state, timePickerError: string) => ({
      ...state,
      timePickerError,
    })
  );

  readonly setExtraSlotsLoading = this.updater(
    (state, extraSlotsLoading: boolean) => ({
      ...state,
      extraSlotsLoading,
    })
  );

  readonly setSelectTimeGALoading = this.updater(
    (state, selectTimeGALoading: boolean) => ({
      ...state,
      selectTimeGALoading,
    })
  );

  readonly setSubmitLoading = this.updater((state, submitLoading: boolean) => ({
    ...state,
    submitLoading,
  }));

  readonly setSelectDateGALoading = this.updater(
    (state, selectDateGALoading: boolean) => ({
      ...state,
      selectDateGALoading,
    })
  );

  readonly setBookingErrorGALoading = this.updater(
    (state, bookingErrorGALoading: boolean) => ({
      ...state,
      bookingErrorGALoading,
    })
  );

  readonly setAddressSavedLoading = this.updater(
    (state, addressSavedLoading: boolean) => ({
      ...state,
      addressSavedLoading,
    })
  );

  readonly setBookingError = this.updater((state, bookingError: string) => ({
    ...state,
    bookingError,
  }));

  readonly setErrorCount = this.updater((state, errorCount: number) => ({
    ...state,
    errorCount,
  }));

  readonly setErrorContent = this.updater(
    (state, errorContent: ErrorContent) => ({
      ...state,
      errorContent,
    })
  );

  readonly setCollectionData = this.updater(
    (state, collectionData: FormCollectionData) => {
      const isCollectionUpdate = Object.keys(collectionData).includes(
        ServiceCollectionData.COLLECTION_ADDRESS
      );

      if (isCollectionUpdate) {
        collectionData = {
          ...collectionData,
          [ServiceCollectionData.DELIVERY_RETURN_ADDRESS as string]: {
            ...collectionData.COLLECTION_ADDRESS,
          },
        };
      } else {
        collectionData = {
          ...state.collectionData,
          ...collectionData,
        };
      }

      return {
        ...state,
        collectionData: collectionData,
      };
    }
  );

  // Effects

  readonly updatePageLoading = this.effect(
    (pageLoading$: Observable<boolean>) => {
      return pageLoading$.pipe(
        withLatestFrom(this.activeClaim$),
        filter(([loaded, _]) => !loaded),
        tap(([_, claim]) => {
          this._bookingAnalytics.triggerPageView(claim);
          this.setPageLoading(true);
        })
      );
    }
  )(this.pageLoading$);

  readonly availabilityGA = this.effect(
    (availability$: Observable<Availability>) => {
      return availability$.pipe(
        filter((a: Availability) => !!a?.bookingOption),
        withLatestFrom(this.activeClaim$),
        tap(([availability, claim]: [Availability, Claim]) => {
          this._bookingAnalytics.newBlockGA(availability, claim);
        })
      );
    }
  )(this.availability$);

  readonly availabilityFirstDateGA = this.effect(
    (availability$: Observable<Availability>) => {
      return availability$.pipe(
        filter(
          (a: Availability) => !!a?.bookingOption && !!a.bookingDates.length
        ),
        withLatestFrom(this.activeClaim$),
        take(1),
        tap(([availability, claim]: [Availability, Claim]) => {
          this._bookingAnalytics.firstDateGA(availability, claim);
        })
      );
    }
  )(this.availability$);

  readonly updateAvailability = this.effect(
    (bookingOption$: Observable<Api.PutServiceOptionResponse>) => {
      return bookingOption$.pipe(
        filter((bookingOption) => !!bookingOption),
        withLatestFrom(this.availability$, this.activeClaim$),
        tap(
          ([bookingOption, availability, claim]: [
            Api.PutServiceOptionResponse,
            Availability,
            Claim
          ]) => {
            this.setErrorCount(0);
            const bookingDates = this._calendarHelper.convertToCamelCase(
              bookingOption.AvailabilityData as Api.BookingDate[]
            ) as CalendarDay[];
            const endDate = bookingOption?.AvailabilityEndDate;
            const extraAvailability = bookingOption?.ExtraAvailability;
            const firstStartDate = format(new Date(), 'yyyy-MM-dd');
            const blockAvailability =
              (bookingOption.AvailabilityData as Api.BookingDate[]).length -
              availability.totalAvailability;
            const totalAvailability = (
              bookingOption.AvailabilityData as Api.BookingDate[]
            ).length;

            this.setAvailability({
              bookingDates,
              bookingOption,
              endDate,
              firstStartDate,
              extraAvailability,
              totalAvailability,
              blockAvailability,
            });
          }
        )
      );
    }
  )(this.bookingOption$);

  readonly bookingApiError = this.effect(
    (bookingApiError$: Observable<any>) => {
      return bookingApiError$.pipe(
        filter((err) => err?.bookingApiTimeout || err?.slotUnavailable),
        withLatestFrom(
          this.errorCount$ as Observable<number>,
          this.activeClaim$
        ),
        tap(([error, count, claim]: [ErrorType, number, Claim]) => {
          const content = this._claimEffectsHelper.bookingErrorContent(
            error,
            count,
            claim
          );
          this.setErrorContent(content);
          this._bookingAnalytics.errorModalGA(claim);
          this.setShowErrorPrompt(true);

          if (error?.slotUnavailable) return;
          this.setErrorCount(count + 1);
        })
      );
    }
  )(this.bookingApiError$);

  readonly triggerSelectTimeGA = this.effect(
    (selectTimeGALoading$: Observable<boolean>) => {
      return selectTimeGALoading$.pipe(
        filter(Boolean),
        withLatestFrom(this.activeClaim$, this.currentDateSlotTime$),
        tap(([_, claim, slot]: [unknown, Claim, CalendarSlot | null]) => {
          this._bookingAnalytics.selectTimeGA(claim, slot);
          this.setSelectTimeGALoading(false);
        })
      );
    }
  )(this.selectTimeGALoading$);

  readonly triggerSelectDateGA = this.effect(
    (selectDateGALoading$: Observable<boolean>) => {
      return selectDateGALoading$.pipe(
        filter(Boolean),
        withLatestFrom(this.activeClaim$, this.currentDate$),
        tap(([_, claim, currDate]: [unknown, Claim, CalendarDay | null]) => {
          if (currDate?.date) {
            this._bookingAnalytics.selectDateGA(claim, currDate);
          }
          this.setSelectDateGALoading(false);
        })
      );
    }
  )(this.selectTimeGALoading$);

  readonly triggerBookingErrorGA = this.effect(
    (bookingErrorGALoading$: Observable<boolean>) => {
      return bookingErrorGALoading$.pipe(
        filter(Boolean),
        withLatestFrom(this.activeClaim$, this.bookingError$),
        tap(([_, claim, error]: [unknown, Claim, string]) => {
          this._bookingAnalytics.bookingErrorGA(error, claim);
          this.setBookingErrorGALoading(false);
        })
      );
    }
  )(this.bookingErrorGALoading$);

  readonly triggerAddressSavedGA = this.effect(
    (addressSavedLoading$: Observable<boolean>) => {
      return addressSavedLoading$.pipe(
        filter(Boolean),
        withLatestFrom(this.activeClaim$),
        tap(([_, claim]: [unknown, Claim]) => {
          this._bookingAnalytics.addressSavedGA(claim);
          this.setAddressSavedLoading(false);
        })
      );
    }
  )(this.addressSavedLoading$);

  readonly triggerSubmit = this.effect(
    (submitLoading$: Observable<boolean>) => {
      return submitLoading$.pipe(
        filter(Boolean),
        withLatestFrom(
          this.silentSlotsLoading$,
          this.currentDate$,
          this.currentDateSlotTime$,
          this.activeClaim$,
          this.availability$,
          this.collectionData$
        ),
        tap(
          ([
            _,
            silentSlotsLoading,
            currentDate,
            currentDateSlotTime,
            claim,
            availability,
            collectionData,
          ]) => {
            if (silentSlotsLoading) {
              this._claim.cancelSilentGetBookingExtraSlots();
            }

            if (!currentDate) {
              this.bookingError(this.dateError);
              this.setTimePickerError(this.dateError);
              this.setSubmitLoading(false);
              return;
            }

            if (!currentDateSlotTime) {
              this.bookingError(this.timeDateError);
              this.setTimePickerError(this.timeDateError);
              this.setSubmitLoading(false);
              return;
            }

            this.setPageLoading(false);
            this._claim.submitBooking(
              claim,
              currentDate,
              currentDateSlotTime,
              collectionData
            );
            this.setSubmitLoading(false);

            this._bookingAnalytics.submitGA(
              claim,
              currentDate,
              currentDateSlotTime,
              availability
            );

            this._bookingAnalytics.selectTimeGA(claim, currentDateSlotTime);
          }
        ) as OperatorFunction<
          unknown,
          [
            boolean,
            boolean,
            CalendarDay,
            CalendarSlot,
            Claim,
            Availability,
            FormCollectionData
          ]
        >
      );
    }
  )(this.submitLoading$);

  readonly showErrorPrompt = this.effect(
    (showErrorPrompt$: Observable<boolean>) => {
      return showErrorPrompt$.pipe(
        filter((showErrorPrompt) => !showErrorPrompt),
        withLatestFrom(
          this.errorCount$ as Observable<number>,
          this.activeClaim$,
          this.bookingApiError$
        ),
        tap(
          ([showErrorPrompt, count, claim, error]: [
            boolean,
            number,
            Claim,
            ErrorType
          ]) => {
            if (error.bookingApiTimeout || error.slotUnavailable) {
              const hasSlots = this._claimEffectsHelper.hasSlots(claim);
              const content = this._claimEffectsHelper.bookingErrorContent(
                error,
                count,
                claim
              );
              this._bookingAnalytics.errorModalCloseGA(claim, content, error);

              if (claim?.slotUnavailable) {
                this._claim.clearBookingErrors();
                return;
              }

              if (count === 1 && hasSlots) {
                this._claim.clearBookingErrors();
                this.setExtraSlotsLoading(true);
                return;
              }

              // GO TO FIRST SLOT
              if (count === 2 && hasSlots) {
                this._claim.clearBookingErrors();
                return;
              }

              // has a date
              if (count === 3) {
                this._claim.clearBookingErrors();
                // GO TO MANUAL FALLBACK
                this._claim.submitManualBooking(
                  claim?.bookingOption?.ManualReferralServiceOptionID as number
                );
                return;
              }
            }
          }
        )
      );
    }
  )(this.showErrorPrompt$);

  readonly loadSlots = this.effect(
    (extraSlotsLoading$: Observable<boolean>) => {
      return extraSlotsLoading$.pipe(
        filter(Boolean),
        withLatestFrom(this.silentSlotsLoading$),
        tap(([_, silentSlotsLoading]: [unknown, boolean]) => {
          if (silentSlotsLoading) {
            this._claim.getBookingExtraSlots();
            this.setSilentSlotsLoading(false);
          } else {
            this._claim.getBookingExtraSlots();
          }
          this.setExtraSlotsLoading(false);
        })
      );
    }
  )(this.extraSlotsLoading$);

  readonly updateWithSelectedDate = this.effect(
    (selectedDate$: Observable<CalendarDay | null>) => {
      return selectedDate$.pipe(
        withLatestFrom(this.currentDate$),
        tap(([selectedDate, currentDate]) => {
          const slotsAvailable = selectedDate?.slotsAvailable;

          if (
            isSameDay(
              (currentDate as CalendarDay)?.date,
              (selectedDate as CalendarDay)?.date
            )
          ) {
            return;
          }

          this.setCurrentDate(
            slotsAvailable ? (selectedDate as CalendarDay) : null
          );

          this.setSlots(
            slotsAvailable
              ? isArray(
                  (selectedDate as CalendarDay).slots as
                    | CalendarSlot[]
                    | CalendarSlot
                )
                ? ((selectedDate as CalendarDay).slots as CalendarSlot[])
                : ([selectedDate?.slots as never] as CalendarSlot[])
              : null
          );

          this.setBookingError('');
          this.setTimePickerError('');

          if (slotsAvailable) {
            this.setSelectDateGALoading(true);
          }
        })
      );
    }
  )(this.selectedDate$);

  // Methods
  onRequestSilentExtraSlots() {
    this.setSilentSlotsLoading(true);
    this._claim.getSilentExtraSlots();
  }

  onSelectTimeSlot(time: CalendarSlot | null) {
    this.setCurrentDateSlotTime(time);
    if (time) {
      this.setSelectTimeGALoading(true);
    }
  }

  bookingError(error: string) {
    this.setBookingError(error);
    this.setBookingErrorGALoading(true);
  }

  onSetCollectionData(
    form: ContactDetailsFormValues,
    type: ServiceCollectionData
  ) {
    this.setCollectionData({
      [type]: { formValues: { ...form } },
    });
  }
}
