<div *ngIf="vm$ | async as vm">
  <div *ngIf="!vm.pageLoading" [@fadeInAnimation]>
    <dg-sub-header [product]="product$ | async"></dg-sub-header>

    <div class="container info-panels">
      <div class="xs-row justify--center row--padding-top-large">
        <div class="xs-12 lg-10">
          <div class="xs-row">
            <div class="xs-12 md-3--none before-sending">
              <img
                src="./assets/images/before-sending.png"
                alt="What happens next?"
              />
            </div>
            <div class="xs-12 md-9--none info">
              <h2>What happens next?</h2>
              <ul>
                <li>
                  We’ll provide you with everything you need so that you can
                  send your device to us by Freepost. In the meantime, confirm
                  the return address that you’d like the device to be sent back
                  to once it’s been repaired.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="xs-row row--padding row--spacer-top-medium">
      <div class="container">
        <div class="flex justify--center">
          <div class="md-10">
            <dg-contact-details
              *ngIf="showDeliveryAddress$ | async"
              [showCancelCTA]="false"
              heading="Confirm the return address:"
              placeholder="Address"
              searchLabel="Start typing the address or postcode"
              [contactDetails]="
                (collectionData$ | async)?.DELIVERY_RETURN_ADDRESS
              "
              [claim]="vm.claim"
              (submitted)="
                saveAddress(
                  $event,
                  ServiceCollectionData.DELIVERY_RETURN_ADDRESS
                )
              "
            >
              <div class="row--spacer-top-medium message--always-visible">
                <p>{{ addressNoticeMessage }}</p>
              </div>
            </dg-contact-details>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        xs-row
        row--padding row--spacer-bottom-large
        flex
        justify--center
        align--center
      "
    >
      <dg-fb-button
        classes="btn btn--primary"
        (click)="onSubmit()"
        [attr.data-automation]="'bookEngineer'"
      >
        Confirm and submit
      </dg-fb-button>
    </div>
  </div>
</div>
