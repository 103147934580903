import { BookingAnalyticsService } from '@domgen/dgx-components';
import { ServiceOption } from '@domgen/dgx-components';
import { FormCollectionData } from '@domgen/data-access-booking';
import { ServiceCollectionData } from '@domgen/dgx-components';
import { ContactDetailsFormValues } from '@domgen/dgx-components';
import { map, filter, tap, withLatestFrom } from 'rxjs/operators';
import { Claim } from '@domgen/dgx-components';
import { ProductDetails } from '@domgen/dgx-components';
import { Observable, OperatorFunction } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { ClaimFacade } from '@domgen/data-access-claims';
import { ClaimHelperService } from '@domgen/data-access-claims';

export interface LocalState {
  pageLoading: Boolean;
  submitLoading: Boolean;
  claim: any;
  collectionData: FormCollectionData | null;
}

@Injectable()
export class SelfSendStateService extends ComponentStore<LocalState> {
  constructor(
    private _claim: ClaimFacade,
    private _claimHelper: ClaimHelperService,
    private _bookingAnalytics: BookingAnalyticsService
  ) {
    super({
      pageLoading: true,
      submitLoading: false,
      claim: null,
      collectionData: null,
    });
  }

  readonly activeClaim$: Observable<Claim> = this._claim.activeClaim$;
  readonly submitLoading$ = this.select((state) => state.submitLoading);

  readonly product$: Observable<ProductDetails> = this.activeClaim$.pipe(
    map((claim: Claim) => {
      return {
        brand: claim.reflect?.manufacturer,
        applianceName: claim.reflect?.productType,
        planNumber: claim.reflect?.planNumber,
        modelNumber: this._claimHelper.getModelNumberHeading(claim),
      } as ProductDetails;
    })
  );

  readonly pageLoading$ = this.select((state) => state.pageLoading);

  readonly vm$ = this.select(
    this.select((state) => state),
    this.activeClaim$,

    (state, claim) => ({
      ...state,
      claim,
    })
  );

  readonly serviceOption$ = this._claim
    .serviceOption$ as Observable<ServiceOption>;

  readonly collectionData$ = this.select((state) => state.collectionData).pipe(
    filter((v) => v !== null)
  );

  readonly getDeliveryAddress$ = this.serviceOption$.pipe(
    map(
      (opt) =>
        opt?.ServiceOptionRequiredFields &&
        opt.ServiceOptionRequiredFields.includes(
          ServiceCollectionData.DELIVERY_RETURN_ADDRESS
        )
    ),
    withLatestFrom(this.activeClaim$),
    map(([show, claim]: [Boolean, Claim]) => {
      if (show) {
        this.setCollectionData({
          DELIVERY_RETURN_ADDRESS: {
            formValues: {
              address: '',
              addressLine1: claim?.customer?.CustomersHouseStreetName as string,
              addressLine2: '',
              city: claim.customer?.CustomersTownCity as string,
              county: claim.customer?.CustomersLocalArea as string,
              postcode: claim.customer?.CustomersPostCode as string,
            },
          },
        });
      }

      return show;
    })
  );

  // Updaters

  readonly setPageLoading = this.updater((state, pageLoading: Boolean) => ({
    ...state,
    pageLoading,
  }));

  readonly setSubmitLoading = this.updater((state, submitLoading: boolean) => ({
    ...state,
    submitLoading,
  }));

  readonly setCollectionData = this.updater(
    (state, collectionData: FormCollectionData) => {
      const isCollectionUpdate = Object.keys(collectionData).includes(
        ServiceCollectionData.COLLECTION_ADDRESS
      );

      if (isCollectionUpdate) {
        collectionData = {
          ...collectionData,
          [ServiceCollectionData.DELIVERY_RETURN_ADDRESS as string]: {
            ...collectionData.COLLECTION_ADDRESS,
          },
        };
      } else {
        collectionData = {
          ...state.collectionData,
          ...collectionData,
        };
      }

      return {
        ...state,
        collectionData: collectionData,
      };
    }
  );

  readonly triggerSubmit = this.effect(() => {
    return this.submitLoading$.pipe(
      filter(Boolean),
      withLatestFrom(
        this.activeClaim$,
        this.collectionData$
      ) as OperatorFunction<unknown, [boolean, Claim, FormCollectionData]>,
      tap(
        ([_, claim, collectionData]: [boolean, Claim, FormCollectionData]) => {
          this._claim.submitSelfSendBooking(claim, collectionData);
          this._bookingAnalytics.submitSelfSendGA(claim);
        }
      )
    );
  });

  readonly updatePageLoading = this.effect(() => {
    return this.pageLoading$.pipe(
      withLatestFrom(this.activeClaim$),
      filter(([loading, _]) => !!loading),
      tap(([_, claim]) => {
        this._bookingAnalytics.triggerPageView(claim);
        this.setPageLoading(false);
      })
    );
  });

  onSetCollectionData(
    form: ContactDetailsFormValues,
    type: ServiceCollectionData
  ) {
    this.setCollectionData({
      [type]: { formValues: { ...form } },
    });
  }
}
