import { BookingAnalyticsService } from '@domgen/dgx-components';
import { BookSelfSendComponent } from './book-selfsend.component';
import { BookSelfSendRoutingModule } from './book-selfsend-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SharedComponentsUiModule,
  FormBuilderModule,
} from '@domgen/dgx-components';
import { ClaimSharedModule } from '../_shared/claim-shared.module';

@NgModule({
  declarations: [BookSelfSendComponent],
  exports: [BookSelfSendComponent],
  imports: [
    CommonModule,
    BookSelfSendRoutingModule,
    SharedComponentsUiModule,
    ClaimSharedModule,
    FormBuilderModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [BookingAnalyticsService],
})
export class BookSelfSendModule {}
