<article
  class="accordion xs--stack"
  [ngClass]="{
    'accordion--active': isOpen
  }"
>
  <header class="accordion__header">
    <a class="link" (click)="onToggleClick()">
      <ng-content select="[header]"></ng-content>
      <span class="icon icon--sm icon-arrow-down"></span>
    </a>
  </header>
  <section *ngIf="isOpen" @slideInOut class="accordion__content">
    <ng-content select="[content]"></ng-content>
  </section>
</article>
