import { FormCollectionData } from '../interfaces/form-collection-data.interface';
import { Origin } from '../interfaces/dropoff-origin.interface';
import { Api } from './api.interface';
import { ServiceOption } from './service-option.interface';
import { CalendarDay } from './calendar.interface';

export enum ClaimJourneyStateType {
  START = 'Start',
  QA = 'InProgress',
  BOOKING = 'BookEngineer',
  REBOOKING = 'RebookEngineer',
}

export enum ClaimBundleType {
  REPAIR = 'Repair',
  ANNUAL_SERVICE = 'Service',
}

export interface Claim {
  reflect?: Api.Reflect;
  getData?: Api.GetMandatoryDataResponse;
  created?: boolean;
  accepted?: boolean;
  complete?: boolean;
  rejected?: boolean;
  isRebook?: boolean;
  claimSelection?: ClaimSelection;
  claimID?: string;
  claimReference?: string;
  customer?: CustomerDetails;
  questions?: ClaimQuestion[];
  bookingAvailable?: boolean;
  bookingSelection?: BookingSelection | null;
  bookedServiceOption?: ServiceOptionTypes;
  slotUnavailable?: boolean;
  bookingApiTimeout?: boolean;
  rejectedMessage?: string;
  noBookingDatesFound?: boolean;
  bookingOption?: Api.PutServiceOptionResponse | null;
  serviceOption?: ServiceOption;
  serviceOptions?: ServiceOption[];
  bundleToken?: string;
  claimConfirmed?: Api.PutRepairDataResponse;
  isGasAppliance?: boolean;
  claimedClientName?: string;
  preClaimStartPageViewed?: PreClaimStartPageViewed;
  dropOffOrigin?: Origin;
  userAddressDetailsUpdated?: ContactDetailsUpdatedStatus;
  userPersonalDetailsUpdated?: ContactDetailsUpdatedStatus;
  isServiceProviderCallback?: boolean;
  modalShownCount?: number;
  callbackQA?: { [key: string]: string };
  callbackWidgetEnabled?: boolean;
}

export interface ContactDetailsUpdatedStatus {
  changed: boolean;
  successfull: boolean;
}

export interface ClaimQuestion {
  question: Api.GetQuestionResponse;
  answer: {
    AnswerID: string;
    AnswerValue: string;
  } | null;
}

export interface ClaimSelection {
  request: Partial<Api.UpdateTransactionRequest>;
  selectionState: {
    modelNumberAlreadyValidated?: boolean;
    PNCNumber?: string;
    MLCode?: string;
    claimStage: ClaimStage;
    faultType?: { id: string; description: string };
    faultTypeAnswer?: { id: string; description: string };
  };
}

export interface BookingSelection {
  date?: string;
  slot?: string;
  collectionData?: FormCollectionData;
  url?: string;
  provider?: Api.ServiceProvider;
}

export interface Availability {
  bookingDates: CalendarDay[];
  bookingOption?: Api.PutServiceOptionResponse;
  endDate?: string;
  firstStartDate?: string;
  extraAvailability?: boolean;
  totalAvailability: number;
  blockAvailability: number;
}

export interface CustomerDetails {
  ClaimID: string;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerTitle: string;
  CustomersEmail: string;
  CustomersHouseStreetName: string;
  CustomersLandLine: string;
  CustomersLocalArea: string;
  CustomersMobile: string;
  CustomersPostCode: string;
  CustomersTownCity: string;
}

export interface UpdateClaimPayload {
  route?: boolean;
  isASV?: boolean;
  selectionState: ClaimSelection['selectionState'];
  request: Partial<Api.UpdateTransactionRequest>;
  getData?: Api.GetMandatoryDataResponse;
}

export type PartialUpdateClaimPayload = Omit<UpdateClaimPayload, 'claim'>;

export type UpdateClaimSuccessPayload = PartialUpdateClaimPayload & {
  reflect?: Api.Reflect;
  getData?: Api.GetMandatoryDataResponse;
};

export type CreateClaimSuccessPayload = PartialUpdateClaimPayload & {
  ClaimID: string;
  question: Api.GetQuestionResponse;
};

export interface RouteClaimParams {
  claim: Claim;
  manufacturers: string[] | undefined;
  productTypes: string[] | undefined;
  triggeredBy: string;
}

export enum PreClaimStartPageViewed {
  GasSafety = 0,
  MaintenanceSignpost,
}

export enum ClaimStage {
  SetOneProductType = '1-SetOneProductType', // if only one product type in list
  AutomaticApplianceId = '2-AutomaticApplianceId', // when model number form bypassed
  ModelNumberSelect = '3-ModelNumberSelect', // in appliance-details
  ApplianceDetailsSubmit = '4-ApplianceDetailsSubmit', // in appliance-details
  ClaimTypeSelect = '5-ClaimTypeSelect', // in repair-details
  CreateClaim = '6-CreateClaim', // in updateClaim$ effect
}

export enum ServiceOptionTypes {
  Manual = 'Manual Referral',
  Home = 'Home Repair',
  Collection = 'Courier',
  SelfSend = 'Self Send',
  DropOff = 'Drop off',
  PayClaim = 'Pay and Claim',
  Callback = 'Callback',
}

export enum ServiceCollectionData {
  COLLECTION_ADDRESS = 'COLLECTION_ADDRESS',
  DELIVERY_RETURN_ADDRESS = 'DELIVERY_RETURN_ADDRESS',
}
