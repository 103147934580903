import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, pluck, switchMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Api, LocalStorageService } from '@domgen/dgx-components';
import { ConfigService } from '@domgen/dgx-components';
import { UserService } from './user.service';
import {
  UpdateContactDetailsAPIRequestData,
  UpdateCorrespondenceAddressAPIRequestData,
} from '@domgen/common-person-types';

enum OrbitMethod {
  START_TRANSACTION = 'StartTransaction',
  GET_MANDATORY_DATA = 'GetMandatoryData',
  UPDATE_TRANSACTION = 'UpdateTransaction',
  GET_DATA = 'GetData',
  GET_QUESTION = 'GetQuestion',
  PUT_ANSWER = 'PutAnswer',
  PUT_NEW_CLAIM = 'PutNewClaim',
  GET_SERVICE_OPTION = 'GetServiceOption',
  GET_TRANSACTION = 'GetTransaction', // todo: No usages found (other than this file)
  PUT_SERVICE_OPTION = 'PutServiceOption',
  PUT_REPAIR_DATA = 'PutRepairData',
  CALLBACK = 'callback', // todo: need to test still working
}

enum ClaimsEndpoint {
  bookClaim = '/book-claim',
  superCategory = '/get-super-category',
  rebookSlots = '/rebook-engineer-slots',
  rebookRepair = '/rebook-engineer',
  getProductAttributes = '/get-product-attributes',
  bundleInspect = '/inspect-bundle',
}
enum PersonServiceEndpoint {
  updateAddress = '/update-correspondence-address',
  updateDetails = '/update-contact-details',
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private conf: ConfigService,
    private user: UserService,
    private localStorage: LocalStorageService
  ) {}
  getApiUrl(endpoint: ClaimsEndpoint) {
    return `${this.conf.API}${endpoint}`;
  }
  getPersonServiceUrl(endpoint: PersonServiceEndpoint) {
    return `${this.conf.commonPersonServiceUrl}${endpoint}`;
  }
  handleError(error: HttpErrorResponse) {
    let errMsg;
    switch (true) {
      case !!error.error?.message:
        // A client-side or network error occurred. Handle it accordingly.
        error.error.message = `${error.status} ${error.error?.message}`;
        console.error('An error occurred:', error.error.message);
        return throwError(error.error);

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      case typeof error.error === 'string':
        errMsg = `${error.status} ${error.error}`;
        console.error('An error occurred:', errMsg);
        return throwError({
          name: 'Api Error',
          message: errMsg,
          errorCode: error.status,
        });

      case !!error.error?.ErrorCode:
        errMsg = `${error.status} Orbit code: ${error.error.ErrorCode}`;
        console.error('An error occurred:', errMsg);
        return throwError({
          name: 'Orbit Error',
          message: errMsg,
          errorCode: error.error.ErrorCode,
          errorDetail: error?.error?.ErrorDetail,
        });

      default:
        console.error(`Backend returned code ${error.status}`);
        return throwError(error);
    }
  }

  getSuperCategory(
    applianceCode: string
  ): Observable<Api.GetSuperCategoryResponse> {
    return this.http
      .post<Api.Response<Api.GetSuperCategoryResponse>>(
        `${this.getApiUrl(ClaimsEndpoint.superCategory)}`,
        {
          applianceCode,
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError({
            status: err.status,
            error: err.error.result,
          } as HttpErrorResponse);
        }),
        pluck('result')
      );
  }

  getReflectData() {
    return this.http
      .post<Api.Response<Api.Reflect>>(
        `${this.getApiUrl(ClaimsEndpoint.bundleInspect)}`,
        {
          fields: [
            'asvOffered',
            'countryCode',
            'claimType',
            'journeyState',
            'applianceCode',
            'makerCode',
            'user',
            'homeTelephone',
            'addressLine1',
            'productType',
            'addressLine2',
            'addressLine3',
            'mobilePhone',
            'addressLine4',
            'postCode',
            'initials',
            'manufacturer',
            'planNumber',
            'customerTitle',
            'surname',
            'firstName',
            'email',
            'claimId',
            'workPhone',
            'rootURL',
            'myAccountURL',
            'personalDetailsPath',
            'dashboardPath',
            'logoutPath',
            'isRiskOwner',
            'crossSellDecision',
            'crossSellReqSource',
            'crossSellReqAction',
            'crossSellStore',
          ],
        },
        { observe: 'response' }
      )
      .pipe(
        switchMap((response) => {
          this.user.correlationID = response.headers.get('x-correlation-id');
          return of(response);
        }),
        catchError((err: HttpErrorResponse) => {
          return this.handleError({
            status: err.status,
            error: err.error.result,
          } as HttpErrorResponse);
        }),
        pluck('body', 'result'),
        catchError(this.handleError)
      );
  }

  rebookServiceOption(): Observable<Api.PutServiceOptionResponse> {
    /* todo: this endpoint does not have the following properties in the spec:
        - ExtraAvailability
        - AvailabilityRequestDays
        - AvailabilityMaximumDays
        - AvailabilityStartDate
        - AvailabilityEndDate
        - AuthorityLimit
        - ManualReferralAllowed
        - ManualReferralServiceOptionID
        - PaymentData - Unused
     */
    return this.http
      .post<Api.Response<Api.PutServiceOptionResponse>>(
        `${this.getApiUrl(ClaimsEndpoint.rebookSlots)}`,
        {
          ChannelCode: this.conf.config?.channelCode,
          CountryCode: this.conf.config?.countryCode,
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError({
            status: err.status,
            error: err.error.result,
          } as HttpErrorResponse);
        }),
        pluck('result')
      );
  }

  rebookRepairOption(
    payload: Api.ReBookEngineer
  ): Observable<Api.PutRepairDataResponse> {
    /*
      todo: missing properties from response:
        - AuthorityLimit - Used
        - ClaimReference - Used
        - FaultSelected - Used
        - ClaimQuestionsArray - Used
        - AvailabilityData - Used
        - ServiceProviderHours - Used
        - SerialNumber - Possibly used?
        - ModelNumber - Possibly used?
        - AppointmentSlot - Unused
        - ContactAddressType - Unused
        - ContactBuilding - Unused
        - ContactEmail - Unused
        - ContactHouseStreetName - Unused
        - ContactLocalArea - Unused
        - ContactMobile - Unused
        - ContactName - Unused
        - ContactPostCode - Unused
        - ContactTownCity - Unused
        - FaultCategorySelected - Unused
        - ManufacturerName - Unused
        - ProductDescription - Unused
        - ProductGroup - Unused
        - ProductType - Unused
        - RepairSlot - Unused
        - ServiceProviderID - Unused
        - ServiceProviderWebsite - Unused
        - Status - Unused
        - StatusCode - Unused
        - StatusDebugging - Unused
     */
    return this.http
      .post<Api.Response<Api.PutRepairDataResponse>>(
        `${this.getApiUrl(ClaimsEndpoint.rebookRepair)}`,
        {
          ...payload,
          ChannelCode: this.conf.config?.channelCode,
          CountryCode: this.conf.config?.countryCode,
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError({
            status: err.status,
            error: err.error.result,
          } as HttpErrorResponse);
        }),
        pluck('result')
      );
  }

  getProductAttributes(): Observable<Api.GetProductAttributesResponse> {
    /*
    todo: this is missing the following properties:
      - PolicyNumber
      - APIName
     */
    return this.http
      .post<Api.Response<Api.GetProductAttributesResponse>>(
        `${this.getApiUrl(ClaimsEndpoint.getProductAttributes)}`,
        {
          ChannelCode: this.conf.config?.channelCode,
          CountryCode: this.conf.config?.countryCode,
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError({
            status: err.status,
            error: err.error.result,
          } as HttpErrorResponse);
        }),
        pluck('result')
      );
  }

  //common person service methods

  updateContactDetails(
    payload: UpdateContactDetailsAPIRequestData
  ): Observable<{ status: string }> {
    return this.http
      .post<any>(
        `${this.getPersonServiceUrl(PersonServiceEndpoint.updateDetails)}`,
        payload
      )
      .pipe(
        catchError((err) =>
          throwError({
            status: err.status,
            error: err.error.result,
          } as HttpErrorResponse)
        ),
        pluck('result')
      );
  }

  updateContactAdress(
    payload: UpdateCorrespondenceAddressAPIRequestData
  ): Observable<{ status: string }> {
    return this.http
      .post<any>(
        `${this.getPersonServiceUrl(PersonServiceEndpoint.updateAddress)}`,
        payload
      )
      .pipe(
        catchError((err) =>
          throwError({
            status: err.status,
            error: err.error.result,
          } as HttpErrorResponse)
        ),
        pluck('result')
      );
  }

  // Orbit methods

  private makeOrbitRequest<R = unknown>(
    method: OrbitMethod,
    parameters: Api.OrbitParameters = {}
  ) {
    return this.localStorage.getItem('GUID').pipe(
      switchMap((guid) => {
        return this.http
          .post<Api.Response<R>>(
            `${this.getApiUrl(ClaimsEndpoint.bookClaim)}`,
            {
              method,
              parameters: {
                ...parameters,
                ChannelCode: this.conf.config?.channelCode,
                CountryCode: this.conf.config?.countryCode,
                ...(method === OrbitMethod.START_TRANSACTION
                  ? {}
                  : {
                      GUID: guid,
                    }),
              },
            }
          )
          .pipe(
            catchError((err: HttpErrorResponse) => {
              return this.handleError({
                status: err.status,
                error: err.error.result,
              } as HttpErrorResponse);
            }),
            pluck('result')
          );
      })
    );
  }

  startTransaction(
    payload: Api.StartTransactionRequest
  ): Observable<Api.StartTransactionResponse> {
    return this.makeOrbitRequest<Api.StartTransactionResponse>(
      OrbitMethod.START_TRANSACTION,
      payload
    );
  }

  getMandatoryData(
    planNumber: string
  ): Observable<Api.GetMandatoryDataResponse> {
    return this.makeOrbitRequest<Api.GetMandatoryDataResponse>(
      OrbitMethod.GET_MANDATORY_DATA,
      { planNumber: planNumber }
    );
  }

  getData(payload: Api.GetDataRequest): Observable<Api.GetDataResponse> {
    return this.makeOrbitRequest<Api.GetDataResponse>(
      OrbitMethod.GET_DATA,
      payload
    );
  }

  getQuestion(payload: Api.GetQuestionRequest) {
    return this.makeOrbitRequest<Api.GetQuestionResponse>(
      OrbitMethod.GET_QUESTION,
      payload
    );
  }

  putAnswer(payload: Api.PutAnswerRequest) {
    return this.makeOrbitRequest<Api.PutAnswerResponse>(
      OrbitMethod.PUT_ANSWER,
      payload
    );
  }

  updateTransaction(payload: Api.UpdateTransactionRequest) {
    return this.makeOrbitRequest<Api.UpdateTransactionResponse>(
      OrbitMethod.UPDATE_TRANSACTION,
      payload
    );
  }

  putNewClaim(payload: Api.PutNewClaimRequest) {
    return this.makeOrbitRequest<Api.PutNewClaimResponse>(
      OrbitMethod.PUT_NEW_CLAIM,
      payload
    );
  }

  getServiceOptions(
    payload: Api.GetServiceOptionRequest
  ): Observable<Api.GetServiceOptionResponse> {
    return this.makeOrbitRequest<Api.GetServiceOptionResponse>(
      OrbitMethod.GET_SERVICE_OPTION,
      payload
    );
  }

  //todo: unused method
  getTransaction(): Observable<Api.GetTransactionResponse> {
    return this.makeOrbitRequest<Api.GetTransactionResponse>(
      OrbitMethod.GET_TRANSACTION
    );
  }

  putServiceOption(
    payload: Api.PutServiceOptionRequest
  ): Observable<Api.PutServiceOptionResponse> {
    return this.makeOrbitRequest<Api.PutServiceOptionResponse>(
      OrbitMethod.PUT_SERVICE_OPTION,
      payload
    );
  }

  putRepairData(
    payload: Api.PutRepairDataRequest
  ): Observable<Api.PutRepairDataResponse> {
    return this.makeOrbitRequest<Api.PutRepairDataResponse>(
      OrbitMethod.PUT_REPAIR_DATA,
      payload
    );
  }

  callbackComplete(payload: Api.CallbackRequest) {
    return this.makeOrbitRequest<Api.CallbackResponse>(
      OrbitMethod.CALLBACK,
      payload
    );
  }
}
