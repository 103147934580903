import { BookingStateService } from '@domgen/data-access-booking';
import {
  ServiceCollectionData,
  ServiceOptionTypes,
} from '@domgen/dgx-components';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  CalendarComponent,
  fadeInAnimation,
  fadeInOut,
  slideInOut,
} from '@domgen/dgx-components';
import { CalendarDay, CalendarSlot } from '@domgen/dgx-components';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { ProductDetails } from '@domgen/dgx-components';
import { Observable } from 'rxjs';
import { ContactDetailsFormValues } from '@domgen/dgx-components';

@Component({
  selector: 'claims-book-collection',
  templateUrl: './book-collection.component.html',
  styleUrls: ['./book-collection.component.scss'],
  providers: [BookingStateService],
  animations: [fadeInAnimation, fadeInOut, slideInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookCollectionComponent implements OnInit {
  currentDateSlotTime: CalendarSlot | null = null;
  timePickerError = '';
  addressNoticeMessage =
    'These address details will only be used for the purpose of this claim. They won’t be updated on your plan.';
  silentCallInProgress = false;
  ServiceOptionTypes = ServiceOptionTypes;
  ServiceCollectionData = ServiceCollectionData;
  @ViewChild('error') error?: ElementRef;
  @ViewChild(CalendarComponent) calendarComponent?: CalendarComponent;

  vm$ = this._state.vm$;

  submitCtaText$ = this._state.isASV$.pipe(
    map((isASV) => (isASV ? 'Continue' : 'Book collection'))
  );

  product$: Observable<ProductDetails> = this._state.product$.pipe(
    map((product: ProductDetails) => ({
      ...product,
      title: 'Arrange collection',
      subtitle: `Your claim has been accepted. You can now arrange for the collection of your item.`,
    }))
  );

  showCollectionAddress$ = this._state.getCollectionAddress$;
  showDeliveryAddress$ = this._state.getDeliveryAddress$;
  collectionData$ = this._state.collectionData$;

  constructor(
    private _title: Title,
    private _state: BookingStateService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._title.setTitle('Arrange collection | Domestic & General');
    this._state.setPageLoading(true);
  }

  closeErrorPrompt(count: number) {
    this._state.setShowErrorPrompt(false);
    if (count === 2) {
      this.calendarComponent?.findFirstAvailableDate();
    }
  }

  selectDate(date: CalendarDay) {
    this._state.setSelectedDate(date);
    setTimeout(() => {
      // handle calendar lib dom refresh
      this._cdr.detectChanges();
    });
  }

  onRequestExtraSlots() {
    this._state.setExtraSlotsLoading(true);
  }

  onRequestSilentExtraSlots() {
    this._state.onRequestSilentExtraSlots();
  }

  onSelectTimeSlot(time: CalendarSlot | null) {
    this._state.onSelectTimeSlot(time as CalendarSlot);
  }

  onSubmit() {
    this._state.setSubmitLoading(true);

    setTimeout(() => {
      // dom ready
      if (this.error?.nativeElement) {
        this.error.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  saveAddress(form: ContactDetailsFormValues, type: ServiceCollectionData) {
    this._state.onSetCollectionData(form, type);
  }

  editSaved() {
    this._state.setAddressSavedLoading(true);
  }
}
