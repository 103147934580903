import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorPageRoutingModule } from './error-page-routing.module';
import { ErrorPageComponent } from './error-page.component';
import {
  SharedComponentsUiModule,
  FormBuilderModule,
} from '@domgen/dgx-components';
import { ClaimSharedModule } from '../_shared/claim-shared.module';

@NgModule({
  declarations: [ErrorPageComponent],
  imports: [
    CommonModule,
    ErrorPageRoutingModule,
    SharedComponentsUiModule,
    ClaimSharedModule,
    FormBuilderModule,
  ],
})
export class ErrorPageModule {}
