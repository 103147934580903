import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Claim } from '@domgen/dgx-components';
import * as fromClaims from '../+state';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { differenceInSeconds } from 'date-fns';
import {
  ErrorPageContent,
  ErrorTypes,
  ContactDetails,
  ErrorMessages,
} from '@domgen/dgx-components';
import { ClaimHelperService } from './claim-helper.service';
import { Api } from '@domgen/dgx-components';
import { ProductDetails } from '@domgen/dgx-components';

enum ClaimTypeNames {
  Breakdown = 'Breakdown',
  Accident = 'Damage Caused by Accident',
  BreakdownBoiler = 'Breakdown (Boiler & Controls)',
  AnnualService = 'Annual Service',
}

class RejectionReasons {
  public static readonly GasLeak = /gas.*emergency.*service/;
  public static readonly BoilerWorkingOrder = /boiler.*working.*order/;
}

enum RejectionMessages {
  Default = `Unfortunately, we are unable to complete your request online. If you’d like to know more about why we couldn’t complete your booking, you can contact us or visit our FAQ page.`,
  NoEngineer = `Unfortunately, we are unable to find an available engineer. But don’t worry – you can continue with your repair booking over the phone.`,
}

export interface BasicErrorKeys {
  name: string;
  message: string;
  errorCode?: string;
  status?: number;
  ManualReferralAllowed?: boolean;
  ManualReferralServiceOptionID?: number;
}

interface ErrPageContent {
  title: string;
  description: string;
  ctaText: string;
  errorType: ErrorTypes;
  shortErrorDescription: string;
}

type ErrorCache =
  | {
      values: BasicErrorKeys;
      timeStamp: number;
    }
  | undefined;

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private readonly defaultErrorDescription = `Unfortunately, we are unable to complete your request online. Don’t worry - you can complete your repair booking over the phone by calling the number below.`;
  private errorCache: ErrorCache;

  constructor(
    private store: Store<fromClaims.State>,
    private router: Router,
    private claimHelper: ClaimHelperService
  ) {}

  cacheError(err: BasicErrorKeys) {
    if (!err) {
      this.errorCache = undefined;
      return;
    }
    this.errorCache = { values: err, timeStamp: Date.now() };
  }

  errorPageContent(): Observable<ErrorPageContent> {
    return this.getClaim().pipe(
      map(({ claim, active }) => ({
        claim,
        active,
        ...this.errorMessages(claim),
        contactDetails: this.getContactDetails(claim),
        product: claim ? this.getProductDetails(claim) : null,
      }))
    );
  }

  private getProductDetails(claim: Claim): ProductDetails {
    return {
      title: this.getErrorTitleHeading(claim),
      brand: claim.reflect?.manufacturer,
      applianceName: claim.reflect?.productType,
      planNumber: claim.reflect?.planNumber,
      modelNumber: this.claimHelper.getModelNumberHeading(claim) as string,
    };
  }

  private getErrorTitleHeading(claim: Claim): string {
    const hasASVType = claim?.getData?.ClaimTypes.find(
      (c: Api.ClaimType) => c.ClaimTypeCode === 'ASV'
    );
    const isASV =
      claim?.reflect?.asvOffered === 'true' || hasASVType
        ? hasASVType?.ClaimTypeID ===
          claim?.claimSelection?.request?.ClaimTypeID
        : false;
    return isASV ? 'Annual Service' : 'Repair details';
  }

  private errorMessages(claim: Claim): ErrPageContent {
    if (this.isTechnicalError()) {
      // 500+ error messages - show Technical Error page
      return this.technicalErrorMessages();
    }

    const currentPage = this.router.url.replace(/\//g, '');
    // start with default messages
    const msgs: ErrPageContent = {
      title: 'We couldn’t book your repair',
      description: this.defaultErrorDescription,
      ctaText: 'Back to Plan Details',
      errorType: currentPage === 'error' ? ErrorTypes.Hard : ErrorTypes.Soft,
      shortErrorDescription: 'Generic Error',
    };

    const claimTypeID = claim?.claimSelection?.request?.ClaimTypeID;
    const claimTypeName = claimTypeID
      ? claim.getData?.ClaimTypes?.find((t) => t.ClaimTypeID === claimTypeID)
          ?.ClaimTypeName
      : null;

    // default Error short description
    if (
      this.errorCacheValid() &&
      claim?.rejectedMessage !== msgs.shortErrorDescription
    ) {
      msgs.shortErrorDescription = this.errorCache?.values?.message || '';
    }

    if (msgs.errorType === ErrorTypes.Soft) {
      // Reject messages
      msgs.title = 'We couldn’t complete your booking';
      msgs.shortErrorDescription = 'Generic Reject';
      msgs.description = claim?.rejectedMessage || RejectionMessages.Default;

      switch (claimTypeName) {
        case ClaimTypeNames.BreakdownBoiler:
          if (claim?.rejectedMessage?.match(RejectionReasons.GasLeak)) {
            msgs.shortErrorDescription = 'Gas Leak';
          }
          break;
        case ClaimTypeNames.AnnualService:
          if (
            claim?.rejectedMessage?.match(RejectionReasons.BoilerWorkingOrder)
          ) {
            msgs.shortErrorDescription = 'Boiler Working Order';
          }
          break;
      }
    } else {
      // Error messages

      if (
        this.errorCacheValid()?.message === ErrorMessages.NoEngineerAvailable
      ) {
        // NoEngineerAvailable error treated as a Reject
        msgs.title = 'We couldn’t find an engineer in your area';
        msgs.description = RejectionMessages.NoEngineer;
      }
    }

    return msgs;
  }

  private errorCacheValid(): BasicErrorKeys | undefined {
    return this.errorCache &&
      differenceInSeconds(Date.now(), this.errorCache?.timeStamp) < 3
      ? this.errorCache.values
      : undefined;
  }

  private isTechnicalError(): boolean {
    return (
      !!this.errorCache?.values?.message?.match(/^5[0-9]{2}\s\w+/) ||
      !!this.errorCache?.values?.status?.toString().match(/^5[0-9]{2}/)
    );
  }

  private technicalErrorMessages(): ErrPageContent {
    return {
      title: 'Something went wrong',
      description: `There’s been a temporary problem with our system and we are unable to process your request online.
      But don’t worry – you can continue with your repair booking over the phone.`,
      ctaText: '',
      errorType: ErrorTypes.Technical,
      shortErrorDescription: this.errorCache?.values?.message || '',
    };
  }

  private getClaim(): Observable<{ claim: Claim; active: boolean }> {
    return this.store.select(fromClaims.getActiveClaim).pipe(
      map((claim) => ({
        claim,
        active: !!claim?.getData,
      }))
    ) as Observable<{ claim: Claim; active: boolean }>;
  }

  private getBookingOEM(claim: Claim): string {
    if (claim?.getData?.BookingOEM) {
      return claim.getData.BookingOEM;
    }

    // dirive BookingOEM
    if (claim?.reflect?.manufacturer) {
      if (
        [
          'CANDY',
          'HOOVER',
          'BAUMATIC',
          'ROSIERES',
          'IBERNA',
          'KELVINATOR',
          'HAIER',
        ].includes(claim.reflect.manufacturer)
      ) {
        return 'Hoover';
      }

      if (
        [
          'HEATRAE',
          'POTTERTON',
          'MYSON THORN',
          'BAXI',
          'REMEHA',
          'MAIN',
          'MEGAFLO',
          'SANTON',
        ].includes(claim.reflect.manufacturer)
      ) {
        return 'Baxi';
      }
    }

    return '';
  }

  private getContactDetails(claim: Claim): ContactDetails {
    if (this.isTechnicalError()) {
      return this.allContactDetails().TECHNICAL;
    }

    return (
      this.allContactDetails()[this.getBookingOEM(claim)] ||
      this.allContactDetails().DEFAULT
    );
  }

  private allContactDetails(): { [key: string]: ContactDetails } {
    const standardRate = `Calls cost the standard UK landline rate.`;
    const defaultCharge = `Calls cost the standard UK landline rate. Calls to 0333 numbers will cost you no more than 01 or 02 numbers from landlines and mobiles. If you get 'inclusive minutes' with your package, calls to 0333 numbers will be part of these.`;
    const defaultNumber = `0333 000 9703`;
    const defaultGetInTouchText = `Need to get in touch?`;
    const defaultCallText = `Give us a call on`;
    return {
      Baxi: {
        BookingOEM: 'Baxi',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: '0344 335 6556',
        SpOpeningHours: standardRate,
        SpCallCosts: 'Lines open Mon-Fri 8am-6pm, Sat-Sun 8:30am-2pm.',
      },
      Hoover: {
        BookingOEM: 'Hoover',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: '0344 499 5599',
        SpOpeningHours: standardRate,
        SpCallCosts:
          'Lines open Mon-Fri 8am-5:30pm, Sat 9am-5pm, Sun 10am-4pm.',
      },
      'Hoover Candy': {
        BookingOEM: 'Hoover Candy',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: '0344 499 5599',
        SpOpeningHours: standardRate,
        SpCallCosts:
          'Lines open Mon-Fri 8am-5:30pm, Sat 9am-5pm, Sun 10am-4pm.',
      },
      Whirlpool: {
        BookingOEM: 'Whirlpool',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: '0344 822 7227',
        SpOpeningHours: standardRate,
        SpCallCosts:
          'Lines open Mon-Fri 8am-6pm, Sat 8:30am-4:30pm, Sun 9:30am-3:30pm.',
      },
      'Whirlpool MB': {
        BookingOEM: 'Whirlpool MB',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
      Electrolux: {
        BookingOEM: 'Electrolux',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
      Beko: {
        BookingOEM: 'Beko',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
      TECHNICAL: {
        BookingOEM: 'TECHNICAL',
        GetInTouchText: null,
        SpCallText: 'Just give us a call on',
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
      DEFAULT: {
        BookingOEM: 'DEFAULT',
        GetInTouchText: defaultGetInTouchText,
        SpCallText: defaultCallText,
        SpPhoneNumber: defaultNumber,
        SpCallCosts: defaultCharge,
      },
    };
  }
}
