import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromClaims from '../+state';
import * as claimActions from '../+state/claim.actions';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@domgen/dgx-components';
import { catchError, switchMap, tap, first } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Claim } from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private store: Store<fromClaims.State>,
    private http: HttpClient,
    private conf: ConfigService
  ) {}

  public correlationID?: string | null = null;

  private readonly defaultHomepageURL: string =
    'https://www.domesticandgeneral.com/';

  logout(routeToLogin: boolean = false, homepageURL?: string) {
    return this.callSignout().pipe(
      switchMap(
        () =>
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>
      ),
      tap((claim: Claim) => {
        this.store.dispatch(claimActions.ClearCurrentClaim());
        routeToLogin
          ? this.redirectToLogin(claim)
          : this.redirectToHomepage(homepageURL);
      }),
      catchError((err) => of(this.store.dispatch(claimActions.Error(err))))
    );
  }

  redirectToHomepage(homepageURL?: string) {
    window.location.href = homepageURL ? homepageURL : this.defaultHomepageURL;
  }

  redirectToLogin(claim: Claim) {
    window.location.href = claim
      ? `${claim?.reflect?.myAccountURL}`
      : this.defaultHomepageURL;
  }

  private callSignout() {
    return this.http
      .post(`${this.conf.myAccountApi}/signout`, {})
      .pipe(catchError(() => of(null)));
  }
}
