<div class="xs-row booking">
  <div class="xs-12--none mt-1 flex justify--center">
    <h3 class="margin--none">Week</h3>
  </div>
  <div class="xs-12--none mt-1 navigation">
    <div class="navigation__cta flex justify--space-between">
      <div class="navigation__cta-left">
        <span
          class="icon icon--xs icon-arrow-left justify--center align--center"
        ></span>
      </div>
      <div class="navigation__cta-right">
        <span
          class="icon icon--xs icon-arrow-right justify--center align--center"
        ></span>
      </div>
    </div>
    <div class="navigation__current-week flex justify--center">
      <h5>6 Sep - 12 Sep</h5>
    </div>
  </div>
</div>
