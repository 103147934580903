import { of } from 'rxjs';

export const BookingStateServiceMock = {
  dateError: 'Select an available date',
  timeDateError: 'Select an available date and time',
  selectedDate$: of(),
  currentDate$: of(),
  silentSlotsLoading$: of(),
  pageLoaded$: of(),
  availability$: of(),
  extraSlotsLoading$: of(),
  selectTimeGALoading$: of(),
  selectDateGALoading$: of(),
  submitLoading$: of(),
  bookingErrorGALoading$: of(),
  bookingError$: of(),
  activeClaim$: of(),
  bookingOption$: of(),
  currentDateSlotTime$: of(),
  isASV$: of(),
  product$: of(),
  vm$: of(),
  collectionData$: of(),
  getDropOffLocations$: of(),
  origin$: of(),
  showDeliveryAddress$: of(),

  setCurrentDateSlotTime: () => {},
  setCurrentDate: () => {},
  setSelectedDate: () => {},
  setSlots: () => {},
  setPageLoading: () => {},
  setSilentSlotsLoading: () => {},
  setAvailability: () => {},
  setShowErrorPrompt: () => {},
  setTimePickerError: () => {},
  setExtraSlotsLoading: () => {},
  setSelectTimeGALoading: () => {},
  setSubmitLoading: () => {},
  setSelectDateGALoading: () => {},
  setBookingErrorGALoading: () => {},
  setBookingError: () => {},
  // Effects

  updateAvailability: () => {},
  triggerSelectTimeGA: () => {},
  triggerSelectDateGA: () => {},
  triggerBookingErrorGA: () => {},
  triggerSubmit: () => {},
  updateNewBlockGA: () => {},
  loadSlots: () => {},
  updatePageLoaded: () => {},
  updateWithSelectedDate: () => {},

  // Methods

  closeErrorPrompt: () => {},
  onRequestSilentExtraSlots: () => {},
  onSelectTimeSlot: () => {},
  bookingError: () => {},
  getDropOffLocation: () => {},
};
