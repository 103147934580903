import { CalendarSlot, CalendarDay } from '../interfaces/calendar.interface';
import { differenceInDays } from 'date-fns';
import { Injectable } from '@angular/core';
import format from 'date-fns/format';
import { Api } from '../interfaces/api.interface';
import { ClaimsAnalyticsService } from './claims-analytics.service';
import {
  Availability,
  Claim,
  ServiceOptionTypes,
} from '../interfaces/claim-state.interface';
import { SelectionType } from '../interfaces/selection-type.interface';
import { ClaimHelperService } from './claim-helper.service';
import type { ErrorContent } from '../interfaces/error-page.interface';
import { ErrorType } from '../interfaces/error-types.interface';

@Injectable()
export class BookingAnalyticsService {
  constructor(
    private _analytics: ClaimsAnalyticsService,
    private _claimHelper: ClaimHelperService
  ) {}

  newBlockGA(availability: Availability, claim: Claim) {
    const available = this.gaBlockLength(availability);

    if (available) {
      const eventLabel = `${available}| Available:${availability.blockAvailability}`;

      switch (claim.bookedServiceOption) {
        case ServiceOptionTypes.Collection:
          this._analytics.claimEventExtended({
            eventClaims: 'arrange-collection-calendar-check',
            eventAction: 'calendar-block-collection-dgx',
            eventLabel,
            claim,
          });
          break;
        case ServiceOptionTypes.Home:
          this._analytics.claimEventExtended({
            eventClaims: 'calendar-availability',
            eventAction: 'calendar-block',
            eventLabel,
            claim: claim,
          });
          break;
      }
    }
  }

  private gaBlockLength(
    availability: Availability,
    blockEndDate?: Date
  ): string | null {
    const length = availability.bookingOption
      ?.AvailabilityRequestDays as number;

    if (
      (availability?.blockAvailability as number) >
      (availability?.bookingOption?.AvailabilityRequestDays as number)
    ) {
      return null;
    }

    if (!availability.bookingOption?.AvailabilityRequestDays) {
      return this.gaTotalBlockLength(availability);
    }

    const block =
      Math.floor(
        differenceInDays(
          blockEndDate ??
            new Date(
              availability?.bookingOption?.AvailabilityEndDate as string
            ),
          new Date(availability.firstStartDate as string)
        ) / length
      ) + 1;
    return `Block:${block}| Length:${length}`;
  }

  private gaTotalBlockLength(availability: Availability) {
    const data = availability?.bookingOption?.AvailabilityData || [];
    const len = data.length;
    const today = new Date();
    const lastAvailableDate = data[len - 1]?.Date || null;
    const difference = lastAvailableDate
      ? differenceInDays(new Date(lastAvailableDate), today) + 2
      : 0;
    return `Block:1| Length:${difference}`;
  }

  triggerPageView(claim: Claim) {
    switch (claim.bookedServiceOption) {
      case ServiceOptionTypes.Collection:
        this._analytics.pageViewEvent({
          pagename: 'arrange-collection-dgx',
          eventClaims: 'arrange-collection',
          claim,
        });

        this._analytics.claimEventExtended({
          eventClaims: 'arrange-collection-availability',
          eventAction: 'number-of-days-availability-collection-dgx',
          eventLabel:
            claim.bookingOption?.AvailabilityData?.length.toString() as string,
          claim,
          fireDistinct: true,
        });

        break;
      case ServiceOptionTypes.DropOff:
        this._analytics.pageViewEvent({
          pagename: 'book-drop-off-dgx',
          eventClaims: 'book-drop-off',
          claim,
        });
        break;

      case ServiceOptionTypes.SelfSend:
        this._analytics.pageViewEvent({
          pagename: 'send-us-device',
          eventClaims: 'send-us-device',
          claim,
        });
        break;
      case ServiceOptionTypes.Home:
        if (
          this._claimHelper.isCallbackBooking(
            claim,
            claim?.bookingOption as Api.PutServiceOptionResponse
          )
        ) {
          this._analytics.pageViewEvent({
            pagename: `calendar-${
              this._claimHelper.isASV(claim) ? 'asv-' : ''
            }no-dates-dgx`,
            eventClaims: `calendar-${
              this._claimHelper.isASV(claim) ? 'asv-' : 'claims'
            }-no-dates`,
            claim,
          });
        } else {
          this._analytics.pageViewEvent({
            pagename: 'book-engineer-dgx',
            eventClaims: 'book',
            claim: claim,
          });

          this._analytics.claimEventExtended({
            eventClaims: 'check-availability',
            eventAction: 'number-of-days-available',
            eventLabel:
              claim?.bookingOption?.AvailabilityData?.length.toString() as string,
            claim: claim,
            fireDistinct: true,
          });
        }

        break;
    }
  }

  bookingErrorGA(error: string, claim: Claim) {
    switch (claim.bookedServiceOption) {
      case ServiceOptionTypes.Collection:
        this._analytics.claimEventExtended({
          eventClaims: 'arrange-collection-book-time-error',
          eventAction: 'TimeSlots-error-dgx',
          eventLabel: error,
          claim,
        });
        break;
      case ServiceOptionTypes.Home:
        this._analytics.claimEventExtended({
          eventClaims: 'book-time-error',
          eventAction: 'TimeSlots-error-dgx',
          eventLabel: error,
          claim: claim,
        });
        break;
    }
  }

  selectTimeGA(claim: Claim, currentDateSlotTime: CalendarSlot | null) {
    switch (claim.bookedServiceOption) {
      case ServiceOptionTypes.Collection:
        this._analytics.claimEventExtended({
          eventClaims: 'arrange-collection-book-time',
          eventAction: 'TimeSlots-collection-dgx',
          eventLabel: this.gaSlotLabel(currentDateSlotTime as CalendarSlot),
          claim,
        });
        break;
      case ServiceOptionTypes.Home:
        this._analytics.claimEventExtended({
          eventClaims: 'book-time',
          eventAction: 'TimeSlots-dgx',
          eventLabel: this.gaSlotLabel(currentDateSlotTime as CalendarSlot),
          claim: claim,
        });
        break;
    }
  }

  private gaSlotLabel(currentDateSlotTime?: CalendarSlot) {
    return currentDateSlotTime?.slotType === 'Specific'
      ? `${currentDateSlotTime?.startTime} - ${currentDateSlotTime?.endTime}`
      : 'All Day';
  }

  selectDateGA(claim: Claim, currentDate: CalendarDay) {
    switch (claim.bookedServiceOption) {
      case ServiceOptionTypes.Collection:
        this._analytics.claimEventExtended({
          eventClaims: 'arrange-collection-book-date',
          eventAction: 'DateSlots-collection-dgx',
          eventLabel: format(currentDate.date, 'yyyy-MM-dd'),
          claim,
        });
        break;
      case ServiceOptionTypes.Home:
        this._analytics.claimEventExtended({
          eventClaims: 'book-date',
          eventAction: 'DateSlots-dgx',
          eventLabel: format(currentDate.date, 'yyyy-MM-dd'),
          claim: claim,
        });
        break;
    }
  }

  submitGA(
    claim: Claim,
    currentDate: CalendarDay,
    currentDateSlotTime: CalendarSlot,
    availability: Availability
  ) {
    const firstbookingDate = format(
      new Date(availability?.bookingDates[0]?.date),
      'dd/MM/yyyy'
    );
    const eventLabel = `Selected:${format(
      currentDate.date,
      'yyyy-MM-dd'
    )}| TimeSlot:${this.gaSlotLabel(currentDateSlotTime)}| ${this.gaBlockLength(
      availability,
      currentDate.date
    )}| TotalAvailable:${availability.totalAvailability}`;

    const selectedFirstDateLabel = `Selected FirstDate:${format(
      new Date(availability?.bookingDates[0]?.date),
      'yyyy-MM-dd'
    )}| `;

    const differenceBetweenDates = differenceInDays(
      new Date(currentDate.date),
      new Date(availability?.bookingDates[0]?.date)
    );

    const firstDateSelected = differenceBetweenDates === 0;

    switch (claim.bookedServiceOption) {
      case ServiceOptionTypes.Collection:
        this._analytics.claimEventExtended({
          eventClaims: 'arrange-collection-calendar-submit',
          eventAction: firstDateSelected
            ? 'calendar-block-submitted-first-date-collection-dgx'
            : 'calendar-block-submitted-collection-dgx',
          claimsFirstAvailableDate: firstbookingDate,
          eventLabel: firstDateSelected
            ? `${selectedFirstDateLabel}${eventLabel}`
            : eventLabel,
          eventValue: differenceBetweenDates.toString(),
          claim,
        });
        break;
      case ServiceOptionTypes.Home:
        this._analytics.claimEventExtended({
          eventClaims: 'calendar-submit',
          eventAction: firstDateSelected
            ? 'calendar-block-submitted-first-date'
            : 'calendar-block-submitted',
          claimsFirstAvailableDate: firstbookingDate,
          eventValue: differenceBetweenDates.toString(),
          eventLabel: firstDateSelected
            ? `${selectedFirstDateLabel}${eventLabel}`
            : eventLabel,
          claim: claim,
        });
        break;
    }
  }

  submitSelfSendGA(claim: Claim) {
    this._analytics.claimEventExtended({
      eventClaims: 'self-send-submit',
      eventAction: 'self-send',
      eventLabel: 'confirm return address',
      claim,
    });
  }

  callbackBookingSendGA(claim: Claim) {
    this._analytics.claimEventExtended({
      eventClaims: `calendar-claims-no-dates`,
      eventAction: `calendar ${
        this._claimHelper.isASV(claim) ? 'asv' : 'claims'
      } no dates`,
      eventLabel: 'complete booking',
      claim,
    });
  }

  addressSavedGA(claim: Claim) {
    const defaults = {
      eventLabel: 'save changes manually',
      claim,
    };
    switch (claim.bookedServiceOption) {
      case ServiceOptionTypes.Collection:
        this._analytics.claimEventExtended({
          eventClaims: 'arrange-collection-save-changes',
          eventAction: 'drop-off deliver back to this address',
          ...defaults,
        });
        break;
      case ServiceOptionTypes.DropOff:
        this._analytics.claimEventExtended({
          eventClaims: 'book-drop-off',
          eventAction: 'drop-off deliver back to this address',
          ...defaults,
        });
        break;
    }
  }

  updatedOrigin(claim: Claim) {
    this._analytics.claimEventExtended({
      eventClaims: 'book-drop-off',
      eventAction: 'drop-off different address-dgx',
      eventLabel: 'selected',
      claim: claim,
    });
  }

  firstDateGA(availability: Availability, claim: Claim) {
    if (availability?.bookingDates[0]?.date) {
      const date = format(
        new Date(availability?.bookingDates[0]?.date),
        'dd/MM/yyyy'
      );

      this._analytics.claimEventExtended({
        eventClaims: 'book',
        eventAction: 'first date shown',
        eventLabel: date,
        claimsFirstAvailableDate: date,
        claim,
      });
    }
  }

  selectedDropOffPoint(
    claim: Claim,
    serviceProvider: Api.ServiceProvider,
    selectionType: SelectionType
  ) {
    this._analytics.claimEventExtended({
      eventClaims: 'book-drop-off',
      eventAction: `drop-off from ${selectionType}-dgx`,
      eventLabel: `${serviceProvider?.ServiceProviderTownCity} | ${serviceProvider?.ServiceProviderPostCode}`,
      claim: claim,
    });
  }

  mapInteractionHighlight(claim: Claim, serviceProvider: Api.ServiceProvider) {
    this._analytics.claimEventExtended({
      eventClaims: 'book-drop-off',
      eventAction: 'drop-off map interactions-dgx',
      eventLabel: `${serviceProvider?.ServiceProviderTownCity} | ${serviceProvider?.ServiceProviderPostCode}`,
      claim: claim,
    });
  }

  saveAddress(claim: Claim) {
    this._analytics.claimEventExtended({
      eventClaims: 'book-drop-off',
      eventAction: 'drop-off deliver back to this address',
      eventLabel: 'save changes manually',
      claim: claim,
    });
  }

  submitDropOffBooking(
    claim: Claim,
    serviceProvider: Api.ServiceProvider,
    selectionType: SelectionType
  ) {
    this._analytics.claimEventExtended({
      eventClaims: 'book-drop-off',
      eventAction: `drop-off submitted from ${selectionType}-dgx`,
      eventLabel: `${serviceProvider?.ServiceProviderTownCity} | ${serviceProvider?.ServiceProviderPostCode}`,
      claim: claim,
    });
  }

  errorModalGA(claim: Claim) {
    this._analytics.pageViewEvent({
      pagename: 'claims-error-calendar-timeout-dgx',
      eventClaims: 'error-calendar-timeout',
      claim,
    });
  }

  errorModalCloseGA(claim: Claim, error: ErrorContent, err: ErrorType) {
    this._analytics.claimEventExtended({
      eventClaims: 'error-calendar-timeout',
      eventAction: `${
        err?.slotUnavailable ? 'error no date avaliable' : 'error timeout'
      }`,
      eventLabel: `${error?.cta}`,
      claim: claim,
    });
  }
}
