import { Component, Input } from '@angular/core';

@Component({
  selector: 'dg-legacy-flip-card',
  templateUrl: './legacy-flip-card.component.html',
  styleUrls: ['./legacy-flip-card.component.scss'],
})
export class LegacyFlipCardComponent {
  @Input() isFlipped = false;

  flipCard() {
    this.isFlipped = !this.isFlipped;
  }
}
