<div class="xs-row booking">
  <div class="xs-12--none flex booking-heading">
    <h2 class="margin--none">Earliest repair date</h2>
    <span class="link ml-1">7 September 2021</span>
  </div>
  <div class="xs-12--none booking-body">
    <p>
      Select a day on the calendar and then pick a time slot. This calendar is
      updated with all the available slots.
    </p>
  </div>
</div>
