import { BookingAnalyticsService } from '@domgen/dgx-components';
import { map, filter, tap, withLatestFrom } from 'rxjs/operators';
import { Claim, ProductDetails } from '@domgen/dgx-components';
import { Observable } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { ClaimFacade } from '@domgen/data-access-claims';
import { ClaimHelperService } from '@domgen/data-access-claims';
import { Title } from '@angular/platform-browser';

export interface LocalState {
  pageLoading: Boolean;
  submitLoading: Boolean;
  claim: Claim | null;
}

@Injectable()
export class BookCallbackStateService extends ComponentStore<LocalState> {
  constructor(
    private _claim: ClaimFacade,
    private _claimHelper: ClaimHelperService,
    private _bookingAnalytics: BookingAnalyticsService,
    private _title: Title
  ) {
    super({
      pageLoading: true,
      submitLoading: false,
      claim: null,
    });
  }

  readonly activeClaim$ = this._claim.activeClaim$;
  readonly submitLoading$ = this.select((state) => state.submitLoading);

  readonly product$ = this.activeClaim$.pipe(
    map((claim: Claim) => {
      return {
        title: `About your ${
          this._claimHelper.isASV(claim) ? 'annual service' : 'repair'
        } date`,
        brand: claim.reflect?.manufacturer,
        applianceName: claim.reflect?.productType,
        planNumber: claim.reflect?.planNumber,
        modelNumber: this._claimHelper.getModelNumberHeading(claim),
      } as ProductDetails;
    })
  ) as Observable<ProductDetails>;

  readonly isVokera$: Observable<boolean> = this.activeClaim$.pipe(
    map(
      (claim: Claim) => claim?.reflect?.manufacturer?.toLowerCase() === 'vokera'
    )
  );

  readonly pageLoading$ = this.select((state) => state.pageLoading);

  readonly vm$ = this.select(
    this.select((state) => state),
    this.activeClaim$,
    this.isVokera$,
    this.product$,
    (state, claim, isVokera, product) => ({
      ...state,
      claim,
      isVokera,
      product,
    })
  );

  // Updaters

  readonly setPageLoading = this.updater((state, pageLoading: Boolean) => ({
    ...state,
    pageLoading,
  }));

  readonly setSubmitLoading = this.updater((state, submitLoading: boolean) => ({
    ...state,
    submitLoading,
  }));

  readonly triggerSubmit = this.effect(() => {
    return this.submitLoading$.pipe(
      filter(Boolean),
      withLatestFrom(this.activeClaim$),
      tap(([_, claim]: [unknown, Claim]) => {
        this._claim.submitCallbackBooking(claim);
        this._bookingAnalytics.callbackBookingSendGA(claim);
      })
    );
  });

  readonly updatePageLoading = this.effect(() => {
    return this.pageLoading$.pipe(
      withLatestFrom(this.activeClaim$, this.product$),
      filter(([loading, _, product]) => !!loading),
      tap(([_, claim, product]) => {
        this._title.setTitle(`${product.title} | Domestic & General`);
        this.setPageLoading(false);
        this._bookingAnalytics.triggerPageView(claim);
      })
    );
  });
}
