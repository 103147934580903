import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarHelperService } from './components/calendar/services/calendar-helper.service';
import { CalendarComponent } from './components/calendar/calendar.component';
import { FlipCardComponent } from './components/flip-card/flip-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { SpecificSlotComponent } from './components/time-picker/specific-slot/specific-slot.component';
import { PrintComponent } from './components/print/print.component';
import { AllDaySlotComponent } from './components/time-picker/all-day-slot/all-day-slot.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { PcaService } from './services/pca.service';
import { FormBuilderModule } from './modules/formbuilder/form-builder.module';
import { ModalDialogAlertComponent } from './components/modal-dialog-alert/modal-dialog-alert.component';
import { OpeningTimesComponent } from './components/opening-times/opening-times.component';
import { LegacyFlipCardComponent } from './components/legacy-flip-card/legacy-flip-card.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BookingCalendarComponent } from './components/booking-calendar/booking-calendar.component';
import { BookingCalendarHeaderComponent } from './components/booking-calendar/booking-calendar-header/booking-calendar-header.component';
import { BookingCalendarNavigationComponent } from './components/booking-calendar/booking-calendar-navigation/booking-calendar-navigation.component';

@NgModule({
  declarations: [
    CalendarComponent,
    FlipCardComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    TimePickerComponent,
    SpecificSlotComponent,
    PrintComponent,
    AllDaySlotComponent,
    SpecificSlotComponent,
    SanitizeHtmlPipe,
    SubHeaderComponent,
    InfoMessageComponent,
    ContactDetailsComponent,
    ModalDialogAlertComponent,
    OpeningTimesComponent,
    LegacyFlipCardComponent,
    AccordionComponent,
    BookingCalendarComponent,
    BookingCalendarHeaderComponent,
    BookingCalendarNavigationComponent,
  ],
  exports: [
    CalendarComponent,
    FlipCardComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    TimePickerComponent,
    SpecificSlotComponent,
    PrintComponent,
    AllDaySlotComponent,
    SpecificSlotComponent,
    SanitizeHtmlPipe,
    TranslateModule,
    SubHeaderComponent,
    InfoMessageComponent,
    ContactDetailsComponent,
    ModalDialogAlertComponent,
    OpeningTimesComponent,
    LegacyFlipCardComponent,
    AccordionComponent,
    BookingCalendarComponent,
    BookingCalendarHeaderComponent,
    BookingCalendarNavigationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    FormBuilderModule,
  ],
  providers: [CalendarHelperService, PcaService],
})
export class SharedComponentsUiModule {}
