import { reduce, find, findIndex } from 'lodash-es';

import { claimHelper } from '../helper/claim-state-helper';
import { Api } from '@domgen/dgx-components';
import {
  Claim,
  ClaimSelection,
  ServiceOptionTypes,
  PreClaimStartPageViewed,
  ClaimStage,
  ClaimBundleType,
  ClaimQuestion,
} from '@domgen/dgx-components';
import { createReducer, on } from '@ngrx/store';
import * as ClaimActionTypes from './claim.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export const ClaimFeatureKey = 'claim';

// State for this feature (Claim)
export interface State extends EntityState<Claim> {
  isLoading: boolean;
  loadingMessage: string | null;
  manufacturers: string[] | undefined;
  productTypes: string[] | undefined;
  applianceSuperCategory: string | null;
  planNumber: string | null;
}

export const adapter: EntityAdapter<Claim> = createEntityAdapter<Claim>({
  selectId: (c: Claim) => c?.reflect?.planNumber as string,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  loadingMessage: null,
  manufacturers: undefined,
  productTypes: undefined,
  applianceSuperCategory: null,
  planNumber: null,
});

export const reducer = createReducer(
  initialState,
  on(ClaimActionTypes.InitializeCurrentClaim, (state) => {
    return { ...state, isLoading: true };
  }),
  on(ClaimActionTypes.GetReflectDataSuccess, (state, { payload }) => {
    const foundClaim = find(state.entities, (c) => {
      return (
        (c?.reflect?.planNumber as string) ===
        (payload.reflect?.planNumber as string)
      );
    });

    const isSameBundleToken = foundClaim?.bundleToken === payload?.bundleToken;

    return {
      ...adapter[isSameBundleToken ? 'upsertOne' : 'setOne'](
        {
          bundleToken: isSameBundleToken
            ? foundClaim?.bundleToken
            : payload?.bundleToken,
          reflect:
            foundClaim && isSameBundleToken
              ? // overwrite reflect.manufacturer and claimType
                // if in Local Storage
                {
                  ...payload.reflect,
                  manufacturer: foundClaim.reflect?.manufacturer,
                  claimType: foundClaim.reflect?.claimType,
                }
              : payload.reflect,
          claimedClientName: payload.reflect.manufacturer,
        },
        state
      ),
      planNumber: payload?.reflect?.planNumber as string,
    };
  }),
  on(ClaimActionTypes.LoadClaimDataSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        ...payload,
        bookingApiTimeout: false,
        slotUnavailable: false,
        bookingOption: null,
        bookingAvailable: false,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClaimAccepted, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        accepted: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.AutoCreateClaim, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        getData: payload.getData,
        reflect: payload?.isASV
          ? {
              ...claim?.reflect,
              claimType: ClaimBundleType.ANNUAL_SERVICE,
            }
          : claim?.reflect,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClaimRejected, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        rejected: true,
        rejectedMessage: payload.rejectedMessage,
      },
      state
    );
  }),
  on(ClaimActionTypes.UpdateManufacturer, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        reflect: {
          ...claim?.reflect,
          manufacturer: payload,
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.UpdateClaimSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        getData: payload.getData,
        claimSelection: {
          selectionState: {
            ...claim?.claimSelection?.selectionState,
            ...payload?.update?.selectionState,
          },
          request: {
            ...claim?.claimSelection?.request,
            ...payload.update.request,
          },
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.GetQuestion, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        claimSelection: <ClaimSelection>{
          request: {
            ...claim?.claimSelection?.request,
            ...payload.update.request,
          },
          selectionState: {
            ...claim?.claimSelection?.selectionState,
            ...payload.update.selectionState,
          },
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.CreateCallbackClaim, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        getData: payload,
        accepted: false,
        created: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.ContactDetailsCallbackClaim, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        accepted: true,
        callbackQA: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetCallbackWidgetSuccess, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        callbackWidgetEnabled: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.CreateClaimSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        accepted: false,
        created: true,
        bookingAvailable: false,
        claimID: payload.response.ClaimID,
        questions: [
          {
            question: payload.response.question,
            answer: null,
          },
        ],
      },
      state
    );
  }),
  on(ClaimActionTypes.PutAnswer, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    const questions = reduce(
      claim?.questions,
      (init: ClaimQuestion[], current: ClaimQuestion) => {
        current = { ...current };
        if (
          Number(current.question.QuestionID) === Number(payload.QuestionID)
        ) {
          current.answer = {
            AnswerID: payload.AnswerID,
            AnswerValue: payload.AnswerValue,
          };
        }

        init.push(current);
        return init;
      },
      []
    );

    return adapter.upsertOne(
      {
        ...claim,
        questions: [...questions],
      },
      state
    );
  }),
  on(ClaimActionTypes.GetQuestionSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    // find index of current question being answered
    const result = findIndex(claim?.questions, (q) => {
      return (
        Number(q.question.QuestionID) === Number(payload.request.QuestionID)
      );
    });

    // filter out all questions after the current one being answered
    const questions = claim?.questions
      ?.filter((q, index) => {
        return index <= result;
      })
      .map((v) => {
        return (v = { ...v });
      }) as ClaimQuestion[];

    questions.push({
      question: payload.response,
      answer: null,
    });

    return adapter.upsertOne(
      {
        ...claim,
        questions: [...questions],
      },
      state
    );
  }),
  on(ClaimActionTypes.LoadServiceOptions, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        serviceOptions: payload,
      },
      state
    );
  }),
  on(
    ClaimActionTypes.UpdateUserAddressDetailsSuccess,
    ClaimActionTypes.UpdateUserAddressDetailsFailed,
    (state, { payload }) => {
      const claim = claimHelper.getClaim(state);
      return adapter.upsertOne(
        {
          ...claim,
          userAddressDetailsUpdated: payload,
        },
        state
      );
    }
  ),
  on(
    ClaimActionTypes.UpdateUserPersonalDetailsSuccess,
    ClaimActionTypes.UpdateUserPersonalDetailsFailed,
    (state, { payload }) => {
      const claim = claimHelper.getClaim(state);
      return adapter.upsertOne(
        {
          ...claim,
          userPersonalDetailsUpdated: payload,
        },
        state
      );
    }
  ),
  on(ClaimActionTypes.SelectServiceOption, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        serviceOption: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetServiceOptions, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        customer: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.ResetBooking, (state) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        bookingApiTimeout: false,
        slotUnavailable: false,
        bookingOption: null,
        bookingAvailable: false,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClaimRebook, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        getData: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.PutServiceOptionSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...payload.response,
          AvailabilityData: [
            ...(claim?.bookingOption?.AvailabilityData || []),
            ...(payload?.response?.AvailabilityData || []),
          ],
        },
        bookingAvailable: true,
        isServiceProviderCallback: payload?.isServiceProviderCallback,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetBookingFollowingWeekSlots, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...payload,
          AvailabilityData: [
            ...(claim?.bookingOption?.AvailabilityData || []),
            ...(payload?.AvailabilityData || []),
          ],
        },
        bookingAvailable: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.DropOffPutServiceOptionSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...payload.response,
        },
        dropOffOrigin: payload.dropOffOrigin,
        bookingAvailable: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.RebookClaimDataSuccess, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        bookingOption: payload,
        bookingAvailable: true,
        bookedServiceOption: ServiceOptionTypes.Home,
        isRebook: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.BookingSlotUnavailable, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookingSelection: null,
        slotUnavailable: true,
        bookingOption: {
          ...claim?.bookingOption,
          AvailabilityData: claim?.bookingOption?.AvailabilityData?.filter(
            (day: Api.BookingDate) =>
              day?.Date === claim?.bookingSelection?.date
          ),
        } as Api.PutServiceOptionResponse,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetBookingExtraSlotsFailed, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        bookingApiTimeout: true,
        bookingOption: {
          ...claim?.bookingOption,
          ...payload,
        } as Api.PutServiceOptionResponse,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClearBookingErrors, (state) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        bookingApiTimeout: false,
        slotUnavailable: false,
      },
      state
    );
  }),
  on(ClaimActionTypes.ManualBooking, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        noBookingDatesFound: payload.noBookingDatesFound,
        bookedServiceOption: ServiceOptionTypes.Manual,
      },
      state
    );
  }),
  on(ClaimActionTypes.CompleteCallbackBooking, (state, { desiredTime }) => {
    const claim = claimHelper.getClaim(state);
    //desiredTime
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.Callback,
      },
      state
    );
  }),
  on(ClaimActionTypes.CollectionBooking, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.Collection,
      },
      state
    );
  }),
  on(ClaimActionTypes.PayAndClaim, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.PayClaim,
      },
      state
    );
  }),
  on(ClaimActionTypes.SelfSendBooking, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.SelfSend,
      },
      state
    );
  }),
  on(ClaimActionTypes.EngineerBooking, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.Home,
      },
      state
    );
  }),
  on(ClaimActionTypes.DropOffBooking, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        dropOffOrigin: payload.dropOffOrigin,
        bookedServiceOption: ServiceOptionTypes.DropOff,
      },
      state
    );
  }),
  on(ClaimActionTypes.PutRepairData, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        bookingSelection: payload.bookingSelection,
        customer: payload.customerDetails,
      },
      state
    );
  }),
  on(ClaimActionTypes.PutRepairDataSuccess, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        claimID: payload.ClaimID,
        claimReference: payload.ClaimReference,
        claimConfirmed: payload,
        complete: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClearCurrentClaim, (state) => {
    const claim = claimHelper.getClaim(state);
    return {
      ...adapter.removeOne(claim.reflect?.planNumber as string, state),
      manufacturers: undefined,
      productTypes: undefined,
      applianceSuperCategory: null,
    };
  }),
  on(ClaimActionTypes.SetManufacturers, (state, { payload }) => {
    return {
      ...state,
      manufacturers: payload,
    };
  }),
  on(ClaimActionTypes.SetProductTypes, (state, { payload }) => {
    return {
      ...state,
      productTypes: payload,
    };
  }),
  on(ClaimActionTypes.GetSuperCategorySuccess, (state, { payload }) => {
    return {
      ...adapter.upsertOne(
        {
          ...claimHelper.getClaim(state),
          isGasAppliance: payload.isGasAppliance,
        },
        state
      ),
      applianceSuperCategory: payload.applianceSuperCategory,
    };
  }),
  on(ClaimActionTypes.SeenGasSafetyNotice, (state) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        preClaimStartPageViewed: PreClaimStartPageViewed.GasSafety,
      },
      state
    );
  }),
  on(ClaimActionTypes.SeenMaintenanceSignpost, (state) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        preClaimStartPageViewed: PreClaimStartPageViewed.MaintenanceSignpost,
      },
      state
    );
  }),
  on(ClaimActionTypes.SetOneProductType, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        claimSelection: {
          request: { ProductType: payload.productType },
          selectionState: { claimStage: ClaimStage.SetOneProductType },
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.Loading, (state, { payload }) => {
    return {
      ...state,
      loadingMessage: payload as string,
      isLoading: true,
    };
  }),
  on(ClaimActionTypes.Loaded, (state) => {
    return {
      ...state,
      loadingMessage: null,
      isLoading: false,
    };
  })
);
