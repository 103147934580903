import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormBuilderLabel } from '../../interfaces/formData.interface';
import { ValidationError } from '../../interfaces/validation-error.interface';
import { Hint } from '../hint/hint.component';

@Component({
  selector: 'dg-fb-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @Input() group?: FormGroup;
  @Input() labelFor?: string;
  @Input() rows?: number;
  @Input() classes?: string;
  @Input() control?: string;
  @Input() showValidation = false;
  @Input() label?: FormBuilderLabel;
  @Input() validators?: ValidationError[];
  @Input() placeholder?: string;
  @Input() chrLimit?: number;
  @Input() hint?: Hint;

  refreshValidation$ = new Subject();

  defaultLimit = 200;

  theLength = 0;

  ngOnInit(): void {
    if (Number(this.chrLimit) === 0 || this.chrLimit === null) {
      this.chrLimit = this.defaultLimit;
    }

    this.theLength =
      (this.chrLimit as number) -
      (this.group?.controls[this.control as string].value?.length || 0);

    if (this.theLength) {
      (
        this.group?.controls[this.control as string] as FormControl
      ).valueChanges.subscribe((value) => {
        this.theLength =
          (this.chrLimit as number) - value?.length || this.defaultLimit;
      });
    }
  }

  onBlur() {
    this.showValidation = true;
    this.refreshValidation$.next();
  }
}
