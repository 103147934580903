import * as fromClaim from './claim.reducer';
import { InjectionToken } from '@angular/core';
import {
  ActionReducerMap,
  Action,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

export interface State {
  [fromClaim.ClaimFeatureKey]: fromClaim.State;
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    [fromClaim.ClaimFeatureKey]: fromClaim.reducer,
  }),
});

export const getClaimFeatureState = createFeatureSelector<
  State,
  fromClaim.State
>(fromClaim.ClaimFeatureKey);

export const getActiveClaim = createSelector(
  getClaimFeatureState,
  (state) => state.entities[state?.planNumber as string]
);

export const bookingDates = createSelector(getActiveClaim, (claim) => {
  return claim?.bookingOption ? claim.bookingOption : null;
});

export const serviceOption = createSelector(getActiveClaim, (claim) => {
  return claim?.serviceOption ? claim.serviceOption : null;
});

export const serviceOptions = createSelector(getActiveClaim, (claim) => {
  return claim?.serviceOptions ? claim.serviceOptions : null;
});

export const dropOffOrigin = createSelector(getActiveClaim, (claim) => {
  return claim?.dropOffOrigin ? claim.dropOffOrigin : null;
});

export const dropoffLocations = createSelector(getActiveClaim, (claim) => {
  return claim?.bookingOption?.ServiceProviders
    ? claim.bookingOption.ServiceProviders
    : null;
});

export const getLoaded = createSelector(getClaimFeatureState, (state) => {
  return !state.isLoading;
});

export const isLoading = createSelector(getClaimFeatureState, (state) => {
  return {
    isLoading: state.isLoading,
    message: state.loadingMessage,
  };
});

export const getManufacturers = createSelector(
  getClaimFeatureState,
  (state) => {
    return state.manufacturers;
  }
);

export const getSuperCategory = createSelector(
  getClaimFeatureState,
  (state) => {
    return state.applianceSuperCategory;
  }
);

export const getProductTypes = createSelector(getClaimFeatureState, (state) => {
  return state.productTypes;
});

export const getBookingErrors = createSelector(getActiveClaim, (claim) => {
  return {
    bookingApiTimeout: claim?.bookingApiTimeout,
    slotUnavailable: claim?.slotUnavailable,
  };
});
