import { BookCollectionRoutingModule } from './book-collection-routing.module';
import { BookCollectionComponent } from './book-collection.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SharedComponentsUiModule,
  FormBuilderModule,
} from '@domgen/dgx-components';
import { ClaimSharedModule } from '../_shared/claim-shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { BookingAnalyticsService } from '@domgen/dgx-components';

@NgModule({
  declarations: [BookCollectionComponent],
  imports: [
    CommonModule,
    BookCollectionRoutingModule,
    SharedComponentsUiModule,
    ClaimSharedModule,
    FormBuilderModule,
    GoogleMapsModule,
  ],
  providers: [BookingAnalyticsService],
  exports: [BookCollectionComponent],
})
export class BookCollectionModule {}
