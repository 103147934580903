import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { ENVIRONMENT, LocalStorageService } from '@domgen/dgx-components';
import { switchMap, catchError, tap, delay } from 'rxjs/operators';
import { throwError, zip, Subscription, of } from 'rxjs';
import { UserService } from './user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

enum AuthHeaderNames {
  REQUEST_SOURCE = 'request-source',
  REQUEST_ACTION = 'request-action',
  CLIENT = 'wl-client',
}

@UntilDestroy()
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private localStorage: LocalStorageService,
    private userService: UserService,
    @Inject(ENVIRONMENT) private environment: any
  ) {}

  public static readonly CHANNEL_CODE: string = 'DGX';
  public static readonly COUNTRY_CODE: string = 'GB';

  public static readonly BUNDLE_TOKEN: string = 'athome_aws_bundle';
  public static readonly GUID: string = 'GUID';
  public static readonly TTL: string = 'x-aws-session-ttl';
  public static readonly ID: string = 'x-correlation-id';

  private timeoutSub?: Subscription | null;

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.localStorage.getItem(HttpInterceptorService.BUNDLE_TOKEN).pipe(
      switchMap((bundle: string) => {
        let httpsReq;

        /* Add headers required for orbit exclude all urls todo with
         - addressy typerhead for address lookup
         - auth for logging out user
         - person service for updating user details
        */

        if (
          !request.url.includes('addressy') &&
          !request.url.includes('/auth/') &&
          !request.url.includes('person-service')
        ) {
          const { req, httpOptions } = this.addHeaders(request, bundle);

          httpsReq = req.clone({
            ...httpOptions,
          });
        } else if (request.url.includes('person-service')) {
          // get unique headers for person service api call
          httpsReq = request.clone(this.getPersonServiceHeaders());
        } else {
          // no headers added
          httpsReq = request.clone();
        }

        return next.handle(httpsReq).pipe(
          tap((response: HttpEvent<any>) => {
            if (response instanceof HttpResponse) {
              const sessionttl = response.headers.get(
                HttpInterceptorService.TTL
              );

              if (sessionttl) {
                this.endAndStartTimer(Number(sessionttl));
              }
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error?.status === 403) {
              return this.userService
                .logout(true)
                .pipe(switchMap(() => throwError(error)));
            } else {
              return throwError(error);
            }
          })
        );
      })
    );
  }

  private addHeaders(request: HttpRequest<any>, token: string) {
    let bundle = {};
    if (token && !request.url.includes('//api.myaccount')) {
      bundle = {
        'X-Aws-Bundle': token.replace(/\ /g, '+'),
        ...(this.userService.correlationID && {
          'x-correlation-id': this.userService.correlationID,
        }),
      };
    }

    if (request.url.includes('/config.json')) {
      bundle = {
        ...bundle,
        'Cache-Control': 'no-cache',
      };
    }

    if (request.url.includes('/signout')) {
      bundle = {
        ...bundle,
        [AuthHeaderNames.REQUEST_SOURCE]:
          this.environment.requestSource || 'DandGUK',
        [AuthHeaderNames.REQUEST_ACTION]:
          this.environment.requestAction || 'MyAccount',
        [AuthHeaderNames.CLIENT]: this.environment.requestSource || 'DandGUK',
      };
    }

    if (
      request.url.includes('api.claims') ||
      request.url.includes('api2.claims') ||
      request.url.includes('api2.my-account') ||
      request.url.includes('api.person-service')
    ) {
      bundle = {
        ...bundle,
        [AuthHeaderNames.REQUEST_SOURCE]:
          this.environment.requestSource || 'DandGUK',
        [AuthHeaderNames.REQUEST_ACTION]:
          this.environment.requestAction || 'QandB',
        [AuthHeaderNames.CLIENT]: this.environment.requestSource || 'DandGUK',
      };
    }

    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'application/json',
        'Content-Type': 'application/json',
        ...bundle,
      }),
      withCredentials: true,
      observe: 'response',
    };

    return {
      req: request.clone({
        ...httpOptions,
      }),
      httpOptions: httpOptions,
    };
  }

  getPersonServiceHeaders() {
    return {
      headers: new HttpHeaders({
        accept: 'application/json',
        'Content-Type': 'application/json',
        'Request-Source': 'DandGUK',
      }),
      withCredentials: true,
      observe: 'response',
    };
  }

  endAndStartTimer(ttl: number) {
    if (this.timeoutSub) {
      this.timeoutSub.unsubscribe();
      this.timeoutSub = null;
    }

    this.timeoutSub = of(true)
      .pipe(
        untilDestroyed(this),
        delay(ttl * 1000),
        switchMap(() => this.userService.logout(true))
      )
      .subscribe();
  }
}
