import { ServiceCollectionData } from '@domgen/dgx-components';
import { ContactDetailsFormValues } from '@domgen/dgx-components';
import { map } from 'rxjs/operators';
import { ProductDetails } from '@domgen/dgx-components';
import { Observable } from 'rxjs';
import { fadeInAnimation } from '@domgen/dgx-components';
import { SelfSendStateService } from './book-selfsend-data-access/book-selfsend-state.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'claims-book-selfsend',
  templateUrl: './book-selfsend.component.html',
  styleUrls: ['./book-selfsend.component.scss'],
  providers: [SelfSendStateService],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookSelfSendComponent implements OnInit {
  vm$ = this._state.vm$;
  ServiceCollectionData = ServiceCollectionData;
  showDeliveryAddress$ = this._state.getDeliveryAddress$;
  collectionData$ = this._state.collectionData$;

  addressNoticeMessage =
    'This address will only be used for the purpose of this claim. It won’t be updated on your plan.';

  product$: Observable<ProductDetails> = this._state.product$.pipe(
    map((product: ProductDetails) => ({
      ...product,
      title: 'Send us your device',
      subtitle: `Your claim has been accepted. You can now send your device to us to be repaired`,
    }))
  );

  constructor(private _title: Title, private _state: SelfSendStateService) {}

  ngOnInit() {
    this._title.setTitle('Send us your device | Domestic & General');
    this._state.setPageLoading(true);
  }

  onSubmit() {
    this._state.setSubmitLoading(true);
  }

  saveAddress(form: ContactDetailsFormValues, type: ServiceCollectionData) {
    this._state.onSetCollectionData(form, type);
  }
}
