<div class="modal">
  <div class="modal-content">
    <div class="xs-row">
      <div class="xs-3">
        <div class="icon-wrapper">
          <svg class="icon icon-alerts">
            <use xlink:href="#icon-alerts"></use>
          </svg>
        </div>
      </div>
      <div class="xs-9">
        <h3>Somethings gone wrong</h3>
        <p>{{ content?.body }}</p>

        <button class="btn btn--secondary" (click)="onClick()">
          {{ content?.cta }}
        </button>
      </div>
    </div>
  </div>
</div>
