import { BookingAnalyticsService } from '@domgen/dgx-components';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilderModule,
  SharedComponentsUiModule,
} from '@domgen/dgx-components';
import { BookCallbackComponent } from './book-callback.component';
import { BookCallbackRoutingModule } from './book-callback-routing.module';

@NgModule({
  declarations: [BookCallbackComponent],
  exports: [BookCallbackComponent],
  imports: [
    CommonModule,
    BookCallbackRoutingModule,
    SharedComponentsUiModule,
    FormBuilderModule,
  ],
  providers: [BookingAnalyticsService],
})
export class BookCallbackModule {}
