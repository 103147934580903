import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Api } from '@domgen/dgx-components';
/* NgRx */
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as claimActions from './claim.actions';
import { ApiService } from '../services/api.service';

@Injectable()
export class CallbackEffects {
  constructor(private actions$: Actions, private claimService: ApiService) {}

  contactDetailsCallbackClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.ContactDetailsCallbackClaim),
      switchMap(() => of(claimActions.ContactDetailsCallbackClaimSuccess()))
    )
  );

  getCallbackWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.GetCallbackWidget),
      switchMap(() => of(claimActions.GetCallbackWidgetSuccess()))
    )
  );

  completeCallbackBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.CompleteCallbackBooking),
      switchMap(() => {
        return this.claimService.callbackComplete({}).pipe(
          map((response: Api.CallbackResponse) => {
            return claimActions.CompleteCallbackSuccess({
              reference: response.CallbackReference,
            });
          })
        );
      })
    )
  );
}
