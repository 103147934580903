<div *ngIf="vm$ | async as vm">
  <dg-modal-dialog-alert
    *ngIf="vm.showErrorPrompt"
    [content]="$any(vm?.errorContent)"
    (closed)="closeErrorPrompt($any(vm?.errorCount))"
  ></dg-modal-dialog-alert>

  <div *ngIf="!vm.pageLoading" [@fadeInAnimation]>
    <dg-sub-header
      *ngIf="product$ | async as product"
      [product]="product"
    ></dg-sub-header>
    <div class="help--page">
      Book a date for your item to be collected. Once fixed, it’ll be delivered
      back to you.
    </div>
    <div class="row--padding-top-large row--padding-bottom-large bg--page-alt">
      <div *ngIf="vm.timePickerError" #error></div>
      <div class="help--calendar">
        <strong>Choose a date that suits you.</strong>
      </div>
      <dg-calendar
        [showUIRows]="true"
        [startPosition]="0"
        [startDirection]="true"
        [extraAvailability]="$any(vm.availability?.extraAvailability)"
        [canRequestExtraAvailability]="true"
        [APICalendarDays]="$any(vm.availability?.bookingDates)"
        [startDate]="vm.availability.firstStartDate"
        [endDate]="vm.availability.endDate"
        (requestExtraSlots)="onRequestExtraSlots()"
        (silentRequestExtraSlots)="onRequestSilentExtraSlots()"
        (daySelected)="selectDate($event)"
      ></dg-calendar>
      <p class="form-input-feedback form--error">
        {{ vm.timePickerError }}
      </p>
    </div>
    <div class="xs-row bg--page-alt" [@slideInOut] *ngIf="vm.currentDate">
      <div class="container">
        <div class="flex justify--center">
          <div class="text--center md-8">
            <div
              [@fadeInOut]
              *ngIf="vm.currentDate"
              style="position: relative; top: -30px"
            >
              <dg-time-picker
                [date]="vm.currentDate"
                (selectedDateTime)="onSelectTimeSlot($event)"
                [serviceOptionType]="ServiceOptionTypes.Collection"
              ></dg-time-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="xs-row row--padding row--spacer-top-large">
      <div class="container">
        <div class="flex justify--center">
          <div class="md-8">
            <dg-contact-details
              *ngIf="showCollectionAddress$ | async"
              [showCancelCTA]="false"
              heading="Collect from this address:"
              placeholder="Address"
              searchLabel="Start typing your address or postcode"
              [contactDetails]="(collectionData$ | async)?.COLLECTION_ADDRESS"
              [claim]="vm.claim"
              (submitted)="
                saveAddress($event, ServiceCollectionData.COLLECTION_ADDRESS)
              "
            >
              <div class="row--spacer-top-medium message--always-visible">
                <p>{{ addressNoticeMessage }}</p>
              </div>
            </dg-contact-details>

            <dg-contact-details
              *ngIf="showDeliveryAddress$ | async"
              [showCancelCTA]="false"
              heading="Deliver back to this address:"
              placeholder="Address"
              searchLabel="Start typing your address or postcode"
              [contactDetails]="
                (collectionData$ | async)?.DELIVERY_RETURN_ADDRESS
              "
              [claim]="vm.claim"
              (editSave)="editSaved()"
              (submitted)="
                saveAddress(
                  $event,
                  ServiceCollectionData.DELIVERY_RETURN_ADDRESS
                )
              "
            >
              <div class="row--spacer-top-medium message--always-visible">
                <p>{{ addressNoticeMessage }}</p>
              </div>
            </dg-contact-details>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        xs-row
        row--padding row--spacer-bottom-large
        flex
        justify--center
        align--center
      "
    >
      <dg-fb-button
        classes="btn btn--primary"
        (click)="onSubmit()"
        [attr.data-automation]="'bookEngineer'"
      >
        {{ submitCtaText$ | async }}
      </dg-fb-button>
    </div>
  </div>
</div>
