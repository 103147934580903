import { Claim } from './claim-state.interface';
import { ProductDetails } from './product-details';

export enum ErrorTypes {
  Hard = 'Hard',
  Soft = 'Soft',
  Technical = 'Technical',
}

export interface ErrorPageContent {
  claim: Claim;
  active: boolean;
  title: string;
  description: string;
  ctaText: string;
  errorType: ErrorTypes;
  shortErrorDescription: string;
  contactDetails: ContactDetails;
  product: ProductDetails | null;
}

export interface ContactDetails {
  BookingOEM: string;
  GetInTouchText: string | null;
  SpCallText: string;
  SpPhoneNumber: string;
  SpOpeningHours?: string;
  SpCallCosts: string;
}

export enum ErrorMessages {
  NoEngineerAvailable = 'PutServiceOption: No Availability',
  NoRecognisedServiceOptionTypes = 'GetServiceOption: No recognised types',
  NoAnnualCodeFound = 'Update Transaction: No annual code found',
  InvalidQuesionType = 'Invalid question type',
}

export interface ErrorContent {
  body?: string;
  cta?: string;
}
