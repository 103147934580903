import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';

export interface User {
  sub: string; // User id
  'custom:user_state': string; // is completed, re password etc
  iss: string; // issuer (cognito user pool)
  'cognito:username': string; // email
  aud: string; // api to target with IGNORE
  event_id: string; // IGNORE internal
  token_use: string; // identifies as type of access token
  auth_time: number; // when loggin happened from server time
  'custom:digital_id': string; // digital ID
  name: string; // user name
  exp: number; // cookie expeiry time unix seconds
  iat: number; // issued cookie from this time
  family_name: string; // user surname
  email: string; // email
}

@Injectable({
  providedIn: 'root',
})
export class CookieTokenService {
  constructor(private cookieService: CookieService) {}

  getCustomerID(): string {
    const cookie_AWS_IDT: any = this.cookieService.get('AWS_IDT');
    if (cookie_AWS_IDT) {
      try {
        const decodedUser: User = jwtDecode(cookie_AWS_IDT);
        if (decodedUser) {
          return decodedUser.sub;
        }
      } catch {
        return 'jwt decode error';
      }
    }
    return 'cookie not found';
  }
}
