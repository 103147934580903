<div *ngIf="vm$ | async as vm">
  <div *ngIf="!vm.pageLoading" [@fadeInAnimation]>
    <dg-sub-header [product]="vm.product"></dg-sub-header>

    <div class="container">
      <div class="xs-row justify--center text--center row--padding-top-large">
        <div class="xs-12 lg-7">
          <h2>Date of your engineer visit</h2>
          <p>
            The service provider is not able to provide any dates for this
            request online.
          </p>
          <p *ngIf="vm.isVokera">
            Please complete your booking and they will contact you directly
            within two working days and between these opening hours Mon-Fri
            08:15-17:00, Sat 08:00-12:00
          </p>
        </div>
      </div>
    </div>

    <div class="xs-row row--padding row--spacer-bottom-large">
      <div class="container">
        <div class="flex justify--center">
          <dg-fb-button
            type="button"
            classes="btn btn--primary"
            (click)="onSubmit()"
            [attr.data-automation]="'bookEngineer'"
          >
            Confirm
          </dg-fb-button>
        </div>
      </div>
    </div>
  </div>
</div>
